/**
 * Print
 *
 * Description: Contains styling for print.
 * @see: https://html5boilerplate.com/
 * Version: 1.0.3
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
@media print {
  :global {
    /*
		* Don't show links that are fragment identifiers,
		* or use the `javascript:` pseudo protocol
		*/
    /*
		* Printing Tables:
		* http://css-discuss.incutio.com/wiki/Printing_Tables
		*/ }
    :global *:not(.imageCaption), :global *:before, :global *:after {
      background: transparent !important;
      color: #000 !important;
      /* Black prints faster:	http://www.sanbeiji.com/archives/953 */
      box-shadow: none !important;
      text-shadow: none !important; }
    :global a, :global a:visited {
      text-decoration: underline; }
    :global article > header img {
      display: none; }
    :global .article-content {
      padding-top: 0;
      width: 100%; }
      :global .article-content > section {
        padding-bottom: 0 !important; }
        :global .article-content > section > div {
          display: none !important; }
    :global .search-filters {
      display: none; }
    :global #main-content > header > div > div {
      background-color: rgba(26, 23, 27, 0.65) !important; }
    :global a[href^="#"]:after, :global a[href^="javascript:"]:after {
      content: ""; }
    :global pre, :global blockquote {
      border: 0.1rem solid #999;
      page-break-inside: avoid; }
    :global img {
      display: none; }
    :global p, :global h2, :global h3 {
      orphans: 3;
      widows: 3; }
    :global h2, :global h3 {
      page-break-after: avoid; }
    :global thead {
      display: table-header-group; }
    :global tr, :global img {
      display: none; }
    :global article > header > div > div {
      padding-bottom: 0 !important; }
    :global div > header, :global footer, :global .related-articles {
      display: none; }
    :global .rteContent blockquote {
      color: #1A171B;
      margin: 0px !important;
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
      border: 0px;
      padding-left: 0px !important; }
      :global .rteContent blockquote p {
        font-style: italic;
        font-weight: 500;
        font-size: large; }
    :global .rteContent img {
      display: none; }
    :global .rteContent figure.imageArticle.imageMedium img {
      display: none; }
    :global .rteContent figure.imageArticle.imageMedium figcaption.imageContent {
      position: relative; }
      :global .rteContent figure.imageArticle.imageMedium figcaption.imageContent div.imageTitle h1 {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 3rem;
        margin-bottom: 2rem;
        padding: 0;
        text-align: left; }
      :global .rteContent figure.imageArticle.imageMedium figcaption.imageContent div.imageTitle p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 3rem;
        margin-bottom: 2rem;
        padding: 0;
        text-align: left; }
      :global .rteContent figure.imageArticle.imageMedium figcaption.imageContent div.imageCaption p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 3rem;
        margin-bottom: 2rem;
        margin-top: -2rem;
        padding: 0;
        text-align: left; }
    :global .related-container {
      display: none; }
    :global ul.tagsContainer {
      display: none; }
    :global .imageBlock .image {
      display: none; }
    :global .promoExtended .image {
      display: none; } }
