/**
  * Pages
  *
  * @description
  * Contains page styles
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Global styles
 * 2.0 - Search page
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.searchButtons .button {
  color: #008080;
  text-decoration: underline;
  font-weight: 500; }
  .searchButtons .button:hover {
    color: shade(#33518C, 10%);
    text-decoration: none; }
  .searchButtons .button:active {
    color: #1A171B;
    color: shade(#33518C, 20%);
    text-decoration: none; }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Buttons
  *
  * @description
  * Contains style for a button
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.searchButtons .button {
  color: #008080;
  text-decoration: underline;
  font-weight: 500; }
  .searchButtons .button:hover {
    color: shade(#33518C, 10%);
    text-decoration: none; }
  .searchButtons .button:active {
    color: #1A171B;
    color: shade(#33518C, 20%);
    text-decoration: none; }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 - Buttons
 *
 * Description: Style for buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
 * 1.0 Global styles
 * -----------------------------------------------------------------------------
 */
.hidden {
  display: none; }

.noResults {
  border-top: 0.1rem solid #E5E5E5;
  margin-top: 0.8rem; }
  @media all and (min-width: 0px) {
    .noResults {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 3rem;
      margin-bottom: 2rem; } }
  @media all and (min-width: 768px) {
    .noResults {
      font-size: 1.8rem;
      margin-top: 0;
      line-height: 3rem;
      margin-bottom: 2rem; } }
  .noResults a {
    color: #33518C;
    text-decoration: none; }
    .noResults a:hover {
      text-decoration: underline; }

.searchButtons {
  text-align: right; }
  @media all and (min-width: 0px) {
    .searchButtons .button {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  @media all and (min-width: 768px) {
    .searchButtons .button {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }

@media (max-width: 768px) {
  .searchFilters {
    margin: 4.8rem 0 -3rem;
    order: -1; } }

/**
 * 2.0 Search page
 * -----------------------------------------------------------------------------
 */
.topSection {
  display: flex;
  justify-content: space-between; }
  .topSection p {
    color: #87888A;
    margin-bottom: 0; }
    @media all and (min-width: 0px) {
      .topSection p {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .topSection p {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
  .topSection .sortBy > div {
    padding-bottom: 0.8rem; }
  .topSection .sortBy > p {
    color: #87888A;
    display: inline-block;
    margin: 0 !important; }
    @media all and (min-width: 0px) {
      .topSection .sortBy > p {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .topSection .sortBy > p {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
  .topSection .sortBy > div > a span:not(.sortTitle) {
    text-decoration: none;
    margin-left: 0.4rem; }
  .topSection .sortBy > div > a:hover .sortTitle {
    text-decoration: underline; }
  .topSection .sortBy > div > a:hover span:not(.sortTitle) {
    text-decoration: none; }
  .topSection .sortBy .sortTitle {
    width: 8.2rem !important;
    font-weight: 500; }
  .topSection .sortBy ul {
    top: 0.8rem; }
  .topSection .sortBy li {
    margin: 0.2rem 0 !important; }
  .topSection .sortBy a:hover {
    text-decoration: none !important; }
  .topSection .sortBy span {
    color: #008080;
    text-decoration: underline;
    text-transform: lowercase; }
    @media all and (min-width: 0px) {
      .topSection .sortBy span {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .topSection .sortBy span {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .topSection .sortBy span:hover {
      text-decoration: none; }
    .topSection .sortBy span.active {
      color: #1A171B;
      text-decoration: none !important;
      pointer-events: none; }

.bottomSection {
  display: flex;
  justify-content: center; }
  .bottomSection .nrOfResults {
    margin-top: 0.8rem; }
    @media all and (min-width: 0px) {
      .bottomSection .nrOfResults {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .bottomSection .nrOfResults {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .bottomSection .nrOfResults p {
      color: #87888A;
      display: inline-block;
      margin: 0; }
    .bottomSection .nrOfResults ul {
      list-style: none;
      display: inline-block;
      margin: 0;
      padding: 0 0 0 0.8rem; }
      .bottomSection .nrOfResults ul li {
        display: inline-block;
        padding-right: 0.8rem; }
        .bottomSection .nrOfResults ul li a {
          color: #008080; }
        .bottomSection .nrOfResults ul li:before {
          content: '|';
          padding-right: 0.8rem; }
        .bottomSection .nrOfResults ul li:first-of-type:before {
          content: '';
          padding-right: 0; }
