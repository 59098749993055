/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.socialMediaLink {
  text-decoration: none;
  color: #1A171B; }
  .socialMediaLink:hover .socialMediaCard {
    box-shadow: 4px 4px 15px 4px rgba(0, 0, 0, 0.05); }
  .socialMediaLink .socialMediaCard {
    position: relative;
    padding: 4.5rem 2.4rem 1.6rem;
    margin-bottom: 2.4rem;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 0.5rem;
    transition: box-shadow ease-in-out 0.3s; }
    .socialMediaLink .socialMediaCard .tag {
      position: absolute;
      top: 2.4rem;
      padding: 0.6rem 0.9rem 0.5rem;
      font-size: 1.1rem;
      line-height: 1rem;
      color: #F9F9F9;
      text-transform: uppercase;
      border-radius: 0.4rem; }
      .socialMediaLink .socialMediaCard .tag.twitter {
        background-color: #1da1f2; }
      .socialMediaLink .socialMediaCard .tag.instagram {
        background-color: #4857b7; }
      .socialMediaLink .socialMediaCard .tag.facebook {
        background-color: #1877f2; }
    .socialMediaLink .socialMediaCard .image {
      width: calc(100% + 4.8rem);
      margin: -4.5rem -2.4rem 0;
      max-height: 29rem;
      object-fit: cover; }
    .socialMediaLink .socialMediaCard .summaryText {
      margin-top: 1.6rem;
      font-size: 1.4rem;
      line-height: 2.2rem; }
      .socialMediaLink .socialMediaCard .summaryText a {
        color: #008080;
        text-decoration: none; }
        .socialMediaLink .socialMediaCard .summaryText a:hover {
          text-decoration: underline; }
    .socialMediaLink .socialMediaCard .signatureWrapper {
      margin-top: 1.6rem;
      display: flex; }
      .socialMediaLink .socialMediaCard .signatureWrapper .logo {
        width: 4rem;
        height: 4rem;
        margin-right: 2rem; }
      .socialMediaLink .socialMediaCard .signatureWrapper .signature .signatureHeading {
        margin: 0;
        font-size: 1.6rem;
        font-weight: bold; }
      .socialMediaLink .socialMediaCard .signatureWrapper .signature .signatureFooter {
        margin: 0;
        font-size: 1.2rem;
        color: #a9aaac; }
      .socialMediaLink .socialMediaCard .signatureWrapper .signature .signatureTime {
        margin-left: 0.6rem; }
        .socialMediaLink .socialMediaCard .signatureWrapper .signature .signatureTime:before {
          content: '\00B7';
          margin-right: 0.6rem;
          font-size: 1.6rem; }
    .socialMediaLink .socialMediaCard .footer {
      display: flex;
      margin: 1.6rem -2.4rem 0;
      border-top: 1px solid #E5E5E5;
      padding: 1.6rem 2.4rem 0; }
      .socialMediaLink .socialMediaCard .footer .iconWrapper {
        margin-right: 1.6rem;
        display: flex;
        justify-content: center; }
        .socialMediaLink .socialMediaCard .footer .iconWrapper .interactions {
          margin-left: 0.4rem;
          font-size: 1.4rem;
          color: #a9aaac; }
      .socialMediaLink .socialMediaCard .footer .shareIconWrapper {
        margin-left: auto; }
