/**
  * @stylesheet components/MainMenu.scss MainMenu
  * @parent styleguide
  *
  * @description
  * Styling for mainMenu.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.mainMenu {
  display: inline-block; }
  .mainMenu .list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 2.4rem;
    display: inline-block;
    text-align: center; }
    .mainMenu .list .item, .mainMenu .list .itemActive {
      display: inline-block;
      font-weight: 500;
      margin-right: 2.4rem;
      margin-left: 1.5rem;
      position: relative;
      margin-bottom: 0; }
      @media all and (min-width: 0px) {
        .mainMenu .list .item, .mainMenu .list .itemActive {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.3rem; } }
      @media all and (min-width: 768px) {
        .mainMenu .list .item, .mainMenu .list .itemActive {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.4rem; } }
      @media (max-width: 1024px) {
        .mainMenu .list .item, .mainMenu .list .itemActive {
          margin-right: 0;
          font-size: 1.8rem; } }
      .mainMenu .list .item a, .mainMenu .list .itemActive a {
        text-decoration: none;
        color: #1A171B; }
        .mainMenu .list .item a:hover, .mainMenu .list .item a:active, .mainMenu .list .itemActive a:hover, .mainMenu .list .itemActive a:active {
          border-bottom: 0.2rem solid #008080; }
      .mainMenu .list .item .dropdownItem, .mainMenu .list .item .dropdownItemActive, .mainMenu .list .itemActive .dropdownItem, .mainMenu .list .itemActive .dropdownItemActive {
        line-height: 2rem; }
        @media all and (min-width: 0px) {
          .mainMenu .list .item .dropdownItem, .mainMenu .list .item .dropdownItemActive, .mainMenu .list .itemActive .dropdownItem, .mainMenu .list .itemActive .dropdownItemActive {
            font-size: 1.5rem;
            margin-top: 0;
            line-height: 2.4rem;
            margin-bottom: 1rem; } }
        @media all and (min-width: 768px) {
          .mainMenu .list .item .dropdownItem, .mainMenu .list .item .dropdownItemActive, .mainMenu .list .itemActive .dropdownItem, .mainMenu .list .itemActive .dropdownItemActive {
            font-size: 1.6rem;
            margin-top: 0;
            line-height: 2.4rem;
            margin-bottom: 1rem; } }
      .mainMenu .list .item .dropdownItemActive, .mainMenu .list .itemActive .dropdownItemActive {
        color: #DC001A; }
      .mainMenu .list .item .secondLevel, .mainMenu .list .itemActive .secondLevel {
        list-style: none;
        padding: 0.4rem;
        width: 100%; }
        .mainMenu .list .item .secondLevel :last-child, .mainMenu .list .itemActive .secondLevel :last-child {
          margin-bottom: 0; }
        .mainMenu .list .item .secondLevel :first-child, .mainMenu .list .itemActive .secondLevel :first-child {
          margin-top: 0.4rem; }
        .mainMenu .list .item .secondLevel li, .mainMenu .list .itemActive .secondLevel li {
          line-height: 2rem; }
          @media all and (min-width: 0px) {
            .mainMenu .list .item .secondLevel li, .mainMenu .list .itemActive .secondLevel li {
              font-size: 1.5rem;
              margin-top: 0;
              line-height: 2.4rem;
              margin-bottom: 1rem; } }
          @media all and (min-width: 768px) {
            .mainMenu .list .item .secondLevel li, .mainMenu .list .itemActive .secondLevel li {
              font-size: 1.6rem;
              margin-top: 0;
              line-height: 2.4rem;
              margin-bottom: 1rem; } }
        .mainMenu .list .item .secondLevel li.activeLink a, .mainMenu .list .itemActive .secondLevel li.activeLink a {
          color: #DC001A; }
      .mainMenu .list .item ul, .mainMenu .list .itemActive ul {
        width: 25rem;
        top: 3rem; }
        .mainMenu .list .item ul li, .mainMenu .list .itemActive ul li {
          line-height: 2rem;
          margin-bottom: 0.8rem; }
          .mainMenu .list .item ul li a:hover, .mainMenu .list .itemActive ul li a:hover {
            border-bottom: none; }
    .mainMenu .list .itemActive > div > a {
      color: #DC001A; }
