/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.loader {
  font-size: 3rem;
  line-height: 3rem;
  vertical-align: middle;
  text-align: center;
  display: block;
  animation: spin 2s infinite linear; }
  .loader.large {
    font-size: 6rem;
    line-height: 6rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem 0; }
  .loader.small {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0 1.6rem; }

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1); }
  100% {
    transform: rotate(359deg) scale(1); } }
