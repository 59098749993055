/**
  * @stylesheet components/ArticleHighlights.scss ArticleHighlights
  * @parent styleguide
  *
  * @description
  * Styling for a container with article highlights.
  *
  * version
  * 3.0.0
  *
**/
/**
 * Table of Contents:
 *
 * 1.0 - Article highlights
 *    1.1 - Block article
 * 2.0 - Media queries
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Article header
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.headerHighlights .titleOverlay h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .headerHighlights .titleOverlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .headerHighlights .titleOverlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.articleHighlights {
  background-color: #fff; }

.headerHighlights {
  max-height: 72rem;
  position: relative;
  overflow: hidden; }
  .headerHighlights .highlightsImage img {
    display: block;
    max-height: 72rem;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  .headerHighlights .contentOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column; }
    .headerHighlights .contentOverlay.static {
      position: static;
      margin-top: 4rem; }
  .headerHighlights .titleOverlay {
    background-color: rgba(26, 23, 27, 0.65);
    padding: 2.4rem 0.8rem; }
    .headerHighlights .titleOverlay h1 {
      color: #fff;
      margin-bottom: 0;
      padding: 0 2.4rem; }
    .headerHighlights .titleOverlay .tagsContainer {
      margin-top: -4rem;
      padding: 0 2.4rem; }
  .headerHighlights .tagsList {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0; }
    .headerHighlights .tagsList li {
      display: inline-block; }

.articlesContainer {
  padding: 2.4rem; }

/**
 * 2.0 Media queries
 * -----------------------------------------------------------------------------
 */
@media (max-width: 1024px) {
  .titleOverlay {
    padding: 2.4rem !important; } }

@media (max-width: 767px) {
  .titleOverlay {
    position: static !important; }
    .titleOverlay h1, .titleOverlay .tagsContainer {
      padding: 0 !important; }
  .caption {
    position: static !important; }
  .contentOverlay {
    position: inherit !important; } }

@media print {
  .headerHighlights .tagsList {
    display: none; }
  .headerHighlights .titleOverlay h1 {
    margin-top: 4rem; }
  .headerHighlights .highlightsImage img {
    display: none; } }
