/**
  * @stylesheet components/PromoExtended.scss PromoExtended
  * @parent styleguide
  *
  * @description
  * Styling for the PromoExtended.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.promoExtended .content .container .overlay h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .promoExtended .content .container .overlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .promoExtended .content .container .overlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  .promoExtended .content .container .overlay p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .promoExtended .content .container .overlay p {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
  * Buttons
  *
  * @description
  * Contains style for a button
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.promoExtended .content .container .overlay h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .promoExtended .content .container .overlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .promoExtended .content .container .overlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  .promoExtended .content .container .overlay p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .promoExtended .content .container .overlay p {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 - Buttons
 *
 * Description: Style for buttons
 * -----------------------------------------------------------------------------
 */
.promoExtended .content .container .overlay .button {
  border: 0;
  display: inline-block;
  padding: 1.6rem 2.1rem;
  background-color: #008080;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.4s ease-out;
  text-decoration: none;
  border-radius: 1rem;
  font-size: 1.6rem; }
  .promoExtended .content .container .overlay .button:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #006767; }
  .promoExtended .content .container .overlay .button:active {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0 0 0.8rem rgba(82, 168, 236, 0.6); }

/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.promoExtended {
  position: relative;
  max-height: 80rem; }
  .promoExtended .image img {
    display: block;
    width: 100%;
    max-height: 80rem;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  @media print {
    .promoExtended .image img {
      display: none; } }
  .promoExtended .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end; }
    .promoExtended .content .container {
      width: 1440px;
      margin: 0 auto;
      position: relative; }
      .promoExtended .content .container .overlay {
        width: 55%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        background-color: rgba(26, 23, 27, 0.65);
        color: #fff;
        padding: 3.2rem 2.4rem; }
        .promoExtended .content .container .overlay h1 {
          margin-bottom: 1.5rem; }

/**
 * Media queries
 * -----------------------------------------------------------------------------
 */
@media (max-width: 767px) {
  .content {
    position: relative !important; }
    .content .container {
      width: inherit !important;
      margin: inherit !important; }
      .content .container .overlay {
        border-top-left-radius: inherit !important;
        border-top-right-radius: inherit !important;
        width: inherit !important;
        padding: 2rem !important; } }
