/**
  * @stylesheet components/Fieldset.scss Fieldset
  * @parent styleguide
  *
  * @description
  * Styling for fieldset.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.fieldset legend a {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .fieldset legend a {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  .fieldset legend a {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

.fieldset {
  background-color: #F9F9F9;
  border: none;
  margin-top: -4.2rem;
  margin-bottom: 3.2rem; }
  .fieldset legend {
    display: block;
    position: relative;
    top: 3.5rem;
    width: 100%;
    cursor: pointer;
    margin-bottom: 1rem; }
    .fieldset legend a {
      color: #87888A;
      text-transform: capitalize;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      margin-bottom: 1.5rem; }
    .fieldset legend span {
      float: right; }
  .fieldset .wrapper {
    display: block;
    margin-top: 2rem; }
    .fieldset .wrapper p {
      margin: 0; }
      @media all and (min-width: 0px) {
        .fieldset .wrapper p {
          font-size: 1.5rem;
          margin-top: 0;
          line-height: 2.4rem;
          margin-bottom: 1rem; } }
      @media all and (min-width: 768px) {
        .fieldset .wrapper p {
          font-size: 1.6rem;
          margin-top: 0;
          line-height: 2.4rem;
          margin-bottom: 1rem; } }
  .fieldset .hidden {
    display: none; }
  .fieldset .showMore {
    color: #008080;
    display: block;
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
    @media all and (min-width: 0px) {
      .fieldset .showMore {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .fieldset .showMore {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
  .fieldset label {
    margin: 0 !important;
    display: flex;
    align-items: baseline; }
    @media all and (min-width: 0px) {
      .fieldset label {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .fieldset label {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .fieldset label input {
      margin-right: 0.7rem; }
    .fieldset label input[type="radio"] {
      margin-bottom: 1.4rem; }
    .fieldset label span:first-of-type {
      flex-grow: 1; }
    .fieldset label :global .labelExtra {
      color: #87888A;
      margin: 0; }
      @media all and (min-width: 0px) {
        .fieldset label :global .labelExtra {
          font-size: 1.5rem;
          margin-top: 0;
          line-height: 2.4rem;
          margin-bottom: 1rem; } }
      @media all and (min-width: 768px) {
        .fieldset label :global .labelExtra {
          font-size: 1.6rem;
          margin-top: 0;
          line-height: 2.4rem;
          margin-bottom: 1rem; } }
