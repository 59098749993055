/**
  * @stylesheet components/MainMenu.scss MainMenu
  * @parent styleguide
  *
  * @description
  * Styling for mainMenu.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.mobileMenu {
  display: block;
  border-bottom: 3px solid #e30613; }
  .mobileMenu .list, .mobileMenu .subitemList {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 0.8rem;
    overflow: hidden; }
    .mobileMenu .list .item, .mobileMenu .list .mainItem, .mobileMenu .list .mainItemActive, .mobileMenu .subitemList .item, .mobileMenu .subitemList .mainItem, .mobileMenu .subitemList .mainItemActive {
      font-weight: 500;
      margin-right: 0.8rem;
      margin-left: 1.5rem;
      position: relative;
      margin-bottom: 0; }
      @media all and (min-width: 0px) {
        .mobileMenu .list .item, .mobileMenu .list .mainItem, .mobileMenu .list .mainItemActive, .mobileMenu .subitemList .item, .mobileMenu .subitemList .mainItem, .mobileMenu .subitemList .mainItemActive {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.3rem; } }
      @media all and (min-width: 768px) {
        .mobileMenu .list .item, .mobileMenu .list .mainItem, .mobileMenu .list .mainItemActive, .mobileMenu .subitemList .item, .mobileMenu .subitemList .mainItem, .mobileMenu .subitemList .mainItemActive {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.4rem; } }
      .mobileMenu .list .item a, .mobileMenu .list .mainItem a, .mobileMenu .list .mainItemActive a, .mobileMenu .subitemList .item a, .mobileMenu .subitemList .mainItem a, .mobileMenu .subitemList .mainItemActive a {
        text-decoration: none;
        color: #1A171B;
        font-size: 1.6rem; }
        .mobileMenu .list .item a:hover, .mobileMenu .list .item a:active, .mobileMenu .list .mainItem a:hover, .mobileMenu .list .mainItem a:active, .mobileMenu .list .mainItemActive a:hover, .mobileMenu .list .mainItemActive a:active, .mobileMenu .subitemList .item a:hover, .mobileMenu .subitemList .item a:active, .mobileMenu .subitemList .mainItem a:hover, .mobileMenu .subitemList .mainItem a:active, .mobileMenu .subitemList .mainItemActive a:hover, .mobileMenu .subitemList .mainItemActive a:active {
          border-bottom: 0.2rem solid #008080; }
      .mobileMenu .list .item .subItem, .mobileMenu .list .item .subItemActive, .mobileMenu .list .mainItem .subItem, .mobileMenu .list .mainItem .subItemActive, .mobileMenu .list .mainItemActive .subItem, .mobileMenu .list .mainItemActive .subItemActive, .mobileMenu .subitemList .item .subItem, .mobileMenu .subitemList .item .subItemActive, .mobileMenu .subitemList .mainItem .subItem, .mobileMenu .subitemList .mainItem .subItemActive, .mobileMenu .subitemList .mainItemActive .subItem, .mobileMenu .subitemList .mainItemActive .subItemActive {
        font-size: 1.4rem;
        line-height: 2rem; }
      .mobileMenu .list .item .subItemActive, .mobileMenu .list .mainItem .subItemActive, .mobileMenu .list .mainItemActive .subItemActive, .mobileMenu .subitemList .item .subItemActive, .mobileMenu .subitemList .mainItem .subItemActive, .mobileMenu .subitemList .mainItemActive .subItemActive {
        color: #DC001A; }
      .mobileMenu .list .item .secondLevel, .mobileMenu .list .mainItem .secondLevel, .mobileMenu .list .mainItemActive .secondLevel, .mobileMenu .subitemList .item .secondLevel, .mobileMenu .subitemList .mainItem .secondLevel, .mobileMenu .subitemList .mainItemActive .secondLevel {
        list-style: none;
        padding: 0.4rem;
        margin-left: 0.8rem;
        width: 100%; }
        .mobileMenu .list .item .secondLevel :last-child, .mobileMenu .list .mainItem .secondLevel :last-child, .mobileMenu .list .mainItemActive .secondLevel :last-child, .mobileMenu .subitemList .item .secondLevel :last-child, .mobileMenu .subitemList .mainItem .secondLevel :last-child, .mobileMenu .subitemList .mainItemActive .secondLevel :last-child {
          margin-bottom: 0; }
        .mobileMenu .list .item .secondLevel :first-child, .mobileMenu .list .mainItem .secondLevel :first-child, .mobileMenu .list .mainItemActive .secondLevel :first-child, .mobileMenu .subitemList .item .secondLevel :first-child, .mobileMenu .subitemList .mainItem .secondLevel :first-child, .mobileMenu .subitemList .mainItemActive .secondLevel :first-child {
          margin-top: 0.4rem; }
        .mobileMenu .list .item .secondLevel li, .mobileMenu .list .mainItem .secondLevel li, .mobileMenu .list .mainItemActive .secondLevel li, .mobileMenu .subitemList .item .secondLevel li, .mobileMenu .subitemList .mainItem .secondLevel li, .mobileMenu .subitemList .mainItemActive .secondLevel li {
          line-height: 2rem; }
          .mobileMenu .list .item .secondLevel li a, .mobileMenu .list .mainItem .secondLevel li a, .mobileMenu .list .mainItemActive .secondLevel li a, .mobileMenu .subitemList .item .secondLevel li a, .mobileMenu .subitemList .mainItem .secondLevel li a, .mobileMenu .subitemList .mainItemActive .secondLevel li a {
            font-size: 1.4rem; }
          .mobileMenu .list .item .secondLevel li.activeLink a, .mobileMenu .list .mainItem .secondLevel li.activeLink a, .mobileMenu .list .mainItemActive .secondLevel li.activeLink a, .mobileMenu .subitemList .item .secondLevel li.activeLink a, .mobileMenu .subitemList .mainItem .secondLevel li.activeLink a, .mobileMenu .subitemList .mainItemActive .secondLevel li.activeLink a {
            color: #DC001A; }
      .mobileMenu .list .item ul li, .mobileMenu .list .mainItem ul li, .mobileMenu .list .mainItemActive ul li, .mobileMenu .subitemList .item ul li, .mobileMenu .subitemList .mainItem ul li, .mobileMenu .subitemList .mainItemActive ul li {
        line-height: 2rem;
        margin-bottom: 0.8rem; }
    .mobileMenu .list .mainItem:not(:first-child), .mobileMenu .subitemList .mainItem:not(:first-child) {
      border-top: 1px solid #E5E5E5;
      padding-top: 0.4rem; }
    .mobileMenu .list .mainItem:last-child, .mobileMenu .subitemList .mainItem:last-child {
      margin-bottom: 0.8rem; }
    .mobileMenu .list .mainItemActive > a, .mobileMenu .subitemList .mainItemActive > a {
      color: #DC001A; }
  .mobileMenu .list {
    margin-left: 0.8rem; }
  .mobileMenu .subitemList:not(:last-child) {
    padding-bottom: 0.4rem; }
