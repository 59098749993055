/**
  * @stylesheet components/ArticleIntro.scss ArticleIntro
  * @parent styleguide
  *
  * @description
  * Styling for article intros.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.articleIntro .list .language a {
  color: #33518C;
  text-decoration: none; }
  .articleIntro .list .language a:hover {
    color: shade(#33518C, 10%);
    text-decoration: underline; }
  .articleIntro .list .language a:active {
    color: shade(#33518C, 20%);
    text-decoration: underline; }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.articleIntro {
  border-bottom: 0.1rem solid #E5E5E5;
  margin-bottom: 3.2rem;
  padding-bottom: 3.2rem; }
  .articleIntro :last-child {
    margin-bottom: 0 !important; }
  .articleIntro .topSection {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 1.6rem; }
    @media (max-width: 767px) {
      .articleIntro .topSection {
        flex-direction: column-reverse;
        flex-wrap: wrap; } }
  .articleIntro .inline {
    display: inline-block; }
  .articleIntro .preamble {
    font-weight: 600;
    margin: 3.2rem 0; }
    @media all and (min-width: 0px) {
      .articleIntro .preamble {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 3rem;
        margin-bottom: 2rem; } }
    @media all and (min-width: 768px) {
      .articleIntro .preamble {
        font-size: 1.8rem;
        margin-top: 0;
        line-height: 3rem;
        margin-bottom: 2rem; } }
    .articleIntro .preamble .date {
      color: #008080;
      margin-right: 0.4rem; }
  .articleIntro .italic {
    color: #87888A;
    font-style: italic;
    margin: 0; }
    @media all and (min-width: 0px) {
      .articleIntro .italic {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .articleIntro .italic {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .articleIntro .italic p {
      margin: 0; }
  .articleIntro .list {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0; }
    .articleIntro .list .language {
      display: inline-block;
      padding-right: 0.8rem; }
      .articleIntro .list .language:before {
        content: '|';
        padding-right: 0.8rem;
        font-style: normal; }
      .articleIntro .list .language:first-of-type {
        padding-left: 1.6rem; }
        .articleIntro .list .language:first-of-type:before {
          content: '';
          padding-right: 0; }
      .articleIntro .list .language a {
        font-style: normal; }
      .articleIntro .list .language .currentLanguage {
        font-style: normal;
        color: #1A171B;
        display: inline-block; }
