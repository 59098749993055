/**
  * Progress
  *
  * @description
  * Contains progress styles
  *
  * @version
  * 1.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Global styles
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * 1.0 Global styles
  * -----------------------------------------------------------------------------
  */
:global {
  /* Make clicks pass-through */
  /* Fancy blur effect */
  /* Remove these to get rid of the spinner */ }
  :global #nprogress {
    pointer-events: none; }
  :global #nprogress .bar {
    background: #DC001A;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px; }
  :global #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #DC001A, 0 0 5px #DC001A;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px); }
  :global #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px; }
  :global #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #DC001A;
    border-left-color: #DC001A;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite; }
  :global .nprogress-custom-parent {
    overflow: hidden;
    position: relative; }
  :global .nprogress-custom-parent #nprogress .spinner,
  :global .nprogress-custom-parent #nprogress .bar {
    position: absolute; }

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
