/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global {
  /** All events calender **/ }
  :global .clearfix:before, :global .clearfix:after {
    display: table;
    content: "";
    line-height: 0; }
  :global .clearfix:after {
    clear: both; }
  :global .custom_title {
    margin: em(50px) em(50px) 0; }
  :global .page-all-events {
    margin: 0 em(50px); }
    :global .page-all-events .event-date {
      float: left;
      margin-right: 3%;
      width: 19%; }
      :global .page-all-events .event-date h3 {
        color: #DC001A;
        font-weight: normal;
        padding-top: 0;
        text-align: right;
        margin: 0 0 0.4em 0; }
        @media all and (min-width: 0px) {
          :global .page-all-events .event-date h3 {
            font-size: 2.2rem;
            margin-top: 0;
            line-height: 2.6rem;
            margin-bottom: 1.2rem; } }
        @media all and (min-width: 768px) {
          :global .page-all-events .event-date h3 {
            font-size: 2.4rem;
            margin-top: 0;
            line-height: 3.2rem;
            margin-bottom: 1.6rem; } }
        :global .page-all-events .event-date h3 a {
          color: #DC001A; }
    :global .page-all-events .event-list {
      float: left;
      width: 78%; }
      :global .page-all-events .event-list > .inner {
        border-left: 3px solid #cacaca;
        padding-left: 3%; }
    :global .page-all-events .event-group .event-list .event-separator {
      border-bottom: 1px dotted #cbcbcb;
      height: 1px;
      margin: 17px 0 17px 4.4%; }
    :global .page-all-events .event-group:last-child .event-list .event-separator {
      border-bottom: none;
      margin-top: 0; }
    :global .page-all-events .node-event {
      margin-bottom: 12px; }
      :global .page-all-events .node-event p.date {
        background-color: transparent;
        white-space: nowrap;
        color: #282828;
        float: left; }
      :global .page-all-events .node-event.node-calendar p.date {
        padding: 0;
        margin-top: 0;
        text-align: left;
        width: 10%; }
      :global .page-all-events .node-event.node-calendar.link p.date {
        background-color: transparent;
        color: #DC001A; }
      :global .page-all-events .node-event .text-holder {
        width: 81%;
        float: left;
        padding-left: 5%; }
        :global .page-all-events .node-event .text-holder p {
          margin-bottom: 0;
          margin-top: 0;
          font-weight: bold; }
          :global .page-all-events .node-event .text-holder p.location {
            font-weight: normal;
            font-style: italic; }
    :global .page-all-events .view-content {
      margin-top: 35px; }
    :global .page-all-events .all-events-timeline .all-events-time-line-item {
      float: left;
      padding: 7px 0.875em;
      border-right: 1px solid #cacaca; }
      :global .page-all-events .all-events-timeline .all-events-time-line-item .year {
        font-weight: bold;
        margin: 0 0 0.3em 0; }
      :global .page-all-events .all-events-timeline .all-events-time-line-item:first-child {
        padding-left: 0; }
      :global .page-all-events .all-events-timeline .all-events-time-line-item:last-child {
        padding-right: 0;
        border-right: none; }
      :global .page-all-events .all-events-timeline .all-events-time-line-item .month-nav {
        padding: 0;
        margin: 0;
        list-style: none; }
        :global .page-all-events .all-events-timeline .all-events-time-line-item .month-nav li {
          float: left;
          padding-left: 0.857em; }
          :global .page-all-events .all-events-timeline .all-events-time-line-item .month-nav li:first-child {
            padding-left: 0; }
          :global .page-all-events .all-events-timeline .all-events-time-line-item .month-nav li a {
            color: #87888A;
            font-size: 0.928em; }
      @media (max-width: 1084px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.9em; } }
      @media (max-width: 987px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.85em; } }
      @media (max-width: 940px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.8em; } }
      @media (max-width: 891px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.75em; } }
      @media (max-width: 843px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.7em; } }
      @media (max-width: 794px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          font-size: 0.65em; } }
      @media (max-width: 767px) {
        :global .page-all-events .all-events-timeline .all-events-time-line-item {
          float: none;
          font-size: 1em;
          border-right: none;
          padding-right: 0;
          padding-left: 0; } }
    @media (max-width: 767px) {
      :global .page-all-events .event-date {
        float: none;
        width: auto; }
        :global .page-all-events .event-date h3 {
          margin-bottom: 12px;
          text-align: left; }
      :global .page-all-events .event-list {
        float: none;
        width: auto; }
        :global .page-all-events .event-list > .inner {
          padding-left: 3%; }
      :global .page-all-events .node-event.node-calendar p.date {
        width: 15%; }
      :global .page-all-events .node-event .text-holder {
        padding-left: 0; } }
