/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.container,
.containerFluid {
  margin-right: auto;
  margin-left: auto;
  width: 100%; }

.containerFluid {
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1.2rem;
  margin-left: -1.2rem; }

.row.reverse {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.colXs, .colXsOffset0, .colXsOffset1, .colXsOffset2, .colXsOffset3, .colXsOffset4, .colXsOffset5, .colXsOffset6, .colXsOffset7, .colXsOffset8, .colXsOffset9, .colXsOffset10, .colXsOffset11, .colXsOffset12, .colXs1, .colXs2, .colXs3, .colXs4, .colXs5, .colXs6, .colXs7, .colXs8, .colXs9, .colXs10, .colXs11, .colXs12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 1.2rem;
  padding-left: 1.2rem; }

.colXs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.colXsOffset0 {
  margin-left: 0%; }

.colXsOffset1 {
  margin-left: 8.33333%; }

.colXsOffset2 {
  margin-left: 16.66667%; }

.colXsOffset3 {
  margin-left: 25%; }

.colXsOffset4 {
  margin-left: 33.33333%; }

.colXsOffset5 {
  margin-left: 41.66667%; }

.colXsOffset6 {
  margin-left: 50%; }

.colXsOffset7 {
  margin-left: 58.33333%; }

.colXsOffset8 {
  margin-left: 66.66667%; }

.colXsOffset9 {
  margin-left: 75%; }

.colXsOffset10 {
  margin-left: 83.33333%; }

.colXsOffset11 {
  margin-left: 91.66667%; }

.colXsOffset12 {
  margin-left: 100%; }

.colXs1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.colXs2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.colXs3 {
  flex-basis: 25%;
  max-width: 25%; }

.colXs4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.colXs5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.colXs6 {
  flex-basis: 50%;
  max-width: 50%; }

.colXs7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.colXs8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.colXs9 {
  flex-basis: 75%;
  max-width: 75%; }

.colXs10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.colXs11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.colXs12 {
  flex-basis: 100%;
  max-width: 100%; }

.startXs {
  justify-content: flex-start;
  text-align: start; }

.centerXs {
  justify-content: center;
  text-align: center; }

.endXs {
  justify-content: flex-end;
  text-align: end; }

.topXs {
  align-items: flex-start; }

.middleXs {
  align-items: center; }

.bottomXs {
  align-items: flex-end; }

.aroundXs {
  justify-content: space-around; }

.betweenXs {
  justify-content: space-between; }

.firstXs {
  order: -1; }

.lastXs {
  order: 1; }

@media only screen and (min-width: 767px) {
  .colSm, .colSmOffset0, .colSmOffset1, .colSmOffset2, .colSmOffset3, .colSmOffset4, .colSmOffset5, .colSmOffset6, .colSmOffset7, .colSmOffset8, .colSmOffset9, .colSmOffset10, .colSmOffset11, .colSmOffset12, .colSm1, .colSm2, .colSm3, .colSm4, .colSm5, .colSm6, .colSm7, .colSm8, .colSm9, .colSm10, .colSm11, .colSm12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1.2rem;
    padding-left: 1.2rem; }
  .colSm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .colSmOffset0 {
    margin-left: 0%; }
  .colSmOffset1 {
    margin-left: 8.33333%; }
  .colSmOffset2 {
    margin-left: 16.66667%; }
  .colSmOffset3 {
    margin-left: 25%; }
  .colSmOffset4 {
    margin-left: 33.33333%; }
  .colSmOffset5 {
    margin-left: 41.66667%; }
  .colSmOffset6 {
    margin-left: 50%; }
  .colSmOffset7 {
    margin-left: 58.33333%; }
  .colSmOffset8 {
    margin-left: 66.66667%; }
  .colSmOffset9 {
    margin-left: 75%; }
  .colSmOffset10 {
    margin-left: 83.33333%; }
  .colSmOffset11 {
    margin-left: 91.66667%; }
  .colSmOffset12 {
    margin-left: 100%; }
  .colSm1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .colSm2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .colSm3 {
    flex-basis: 25%;
    max-width: 25%; }
  .colSm4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .colSm5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .colSm6 {
    flex-basis: 50%;
    max-width: 50%; }
  .colSm7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .colSm8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .colSm9 {
    flex-basis: 75%;
    max-width: 75%; }
  .colSm10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .colSm11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .colSm12 {
    flex-basis: 100%;
    max-width: 100%; }
  .startSm {
    justify-content: flex-start;
    text-align: start; }
  .centerSm {
    justify-content: center;
    text-align: center; }
  .endSm {
    justify-content: flex-end;
    text-align: end; }
  .topSm {
    align-items: flex-start; }
  .middleSm {
    align-items: center; }
  .bottomSm {
    align-items: flex-end; }
  .aroundSm {
    justify-content: space-around; }
  .betweenSm {
    justify-content: space-between; }
  .firstSm {
    order: -1; }
  .lastSm {
    order: 1; } }

@media only screen and (min-width: 1024px) {
  .colMd, .colMdOffset0, .colMdOffset1, .colMdOffset2, .colMdOffset3, .colMdOffset4, .colMdOffset5, .colMdOffset6, .colMdOffset7, .colMdOffset8, .colMdOffset9, .colMdOffset10, .colMdOffset11, .colMdOffset12, .colMd1, .colMd2, .colMd3, .colMd4, .colMd5, .colMd6, .colMd7, .colMd8, .colMd9, .colMd10, .colMd11, .colMd12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1.2rem;
    padding-left: 1.2rem; }
  .colMd {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .colMdOffset0 {
    margin-left: 0%; }
  .colMdOffset1 {
    margin-left: 8.33333%; }
  .colMdOffset2 {
    margin-left: 16.66667%; }
  .colMdOffset3 {
    margin-left: 25%; }
  .colMdOffset4 {
    margin-left: 33.33333%; }
  .colMdOffset5 {
    margin-left: 41.66667%; }
  .colMdOffset6 {
    margin-left: 50%; }
  .colMdOffset7 {
    margin-left: 58.33333%; }
  .colMdOffset8 {
    margin-left: 66.66667%; }
  .colMdOffset9 {
    margin-left: 75%; }
  .colMdOffset10 {
    margin-left: 83.33333%; }
  .colMdOffset11 {
    margin-left: 91.66667%; }
  .colMdOffset12 {
    margin-left: 100%; }
  .colMd1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .colMd2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .colMd3 {
    flex-basis: 25%;
    max-width: 25%; }
  .colMd4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .colMd5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .colMd6 {
    flex-basis: 50%;
    max-width: 50%; }
  .colMd7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .colMd8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .colMd9 {
    flex-basis: 75%;
    max-width: 75%; }
  .colMd10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .colMd11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .colMd12 {
    flex-basis: 100%;
    max-width: 100%; }
  .startMd {
    justify-content: flex-start;
    text-align: start; }
  .centerMd {
    justify-content: center;
    text-align: center; }
  .endMd {
    justify-content: flex-end;
    text-align: end; }
  .topMd {
    align-items: flex-start; }
  .middleMd {
    align-items: center; }
  .bottomMd {
    align-items: flex-end; }
  .aroundMd {
    justify-content: space-around; }
  .betweenMd {
    justify-content: space-between; }
  .firstMd {
    order: -1; }
  .lastMd {
    order: 1; } }

@media only screen and (min-width: 1440px) {
  .colLg, .colLgOffset0, .colLgOffset1, .colLgOffset2, .colLgOffset3, .colLgOffset4, .colLgOffset5, .colLgOffset6, .colLgOffset7, .colLgOffset8, .colLgOffset9, .colLgOffset10, .colLgOffset11, .colLgOffset12, .colLg1, .colLg2, .colLg3, .colLg4, .colLg5, .colLg6, .colLg7, .colLg8, .colLg9, .colLg10, .colLg11, .colLg12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1.2rem;
    padding-left: 1.2rem; }
  .colLg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .colLgOffset0 {
    margin-left: 0%; }
  .colLgOffset1 {
    margin-left: 8.33333%; }
  .colLgOffset2 {
    margin-left: 16.66667%; }
  .colLgOffset3 {
    margin-left: 25%; }
  .colLgOffset4 {
    margin-left: 33.33333%; }
  .colLgOffset5 {
    margin-left: 41.66667%; }
  .colLgOffset6 {
    margin-left: 50%; }
  .colLgOffset7 {
    margin-left: 58.33333%; }
  .colLgOffset8 {
    margin-left: 66.66667%; }
  .colLgOffset9 {
    margin-left: 75%; }
  .colLgOffset10 {
    margin-left: 83.33333%; }
  .colLgOffset11 {
    margin-left: 91.66667%; }
  .colLgOffset12 {
    margin-left: 100%; }
  .colLg1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .colLg2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .colLg3 {
    flex-basis: 25%;
    max-width: 25%; }
  .colLg4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .colLg5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .colLg6 {
    flex-basis: 50%;
    max-width: 50%; }
  .colLg7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .colLg8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .colLg9 {
    flex-basis: 75%;
    max-width: 75%; }
  .colLg10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .colLg11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .colLg12 {
    flex-basis: 100%;
    max-width: 100%; }
  .startLg {
    justify-content: flex-start;
    text-align: start; }
  .centerLg {
    justify-content: center;
    text-align: center; }
  .endLg {
    justify-content: flex-end;
    text-align: end; }
  .topLg {
    align-items: flex-start; }
  .middleLg {
    align-items: center; }
  .bottomLg {
    align-items: flex-end; }
  .aroundLg {
    justify-content: space-around; }
  .betweenLg {
    justify-content: space-between; }
  .firstLg {
    order: -1; }
  .lastLg {
    order: 1; } }
