/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.imageBlock .image {
  margin-top: 2.4rem; }
  .imageBlock .image img {
    width: 100%;
    max-height: 80rem;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  @media print {
    .imageBlock .image img {
      display: none; } }
