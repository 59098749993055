/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.events .showMore a {
  color: #008080;
  text-decoration: underline;
  font-weight: 500; }
  .events .showMore a:hover {
    color: shade(#33518C, 10%);
    text-decoration: none; }
  .events .showMore a:active {
    color: #1A171B;
    color: shade(#33518C, 20%);
    text-decoration: none; }

/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.events .eventsContainer {
  margin-top: 4rem; }
  @media (max-width: 1024px) {
    .events .eventsContainer .eventWrapper {
      margin-bottom: 2.4rem; } }
  .events .eventsContainer .event {
    background-color: #fff;
    padding: 1.6rem;
    border-radius: 1rem;
    height: 100%; }
    .events .eventsContainer .event .date {
      color: #DC001A;
      margin: 0; }
    .events .eventsContainer .event h3 {
      margin: 0; }
    .events .eventsContainer .event .location {
      margin: 0.8rem 0; }

.events .showMore {
  text-align: center;
  display: block;
  margin-top: 4rem; }
  @media (max-width: 1024px) {
    .events .showMore {
      margin-top: 1.6rem; } }
  @media all and (min-width: 0px) {
    .events .showMore a {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  @media all and (min-width: 768px) {
    .events .showMore a {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
