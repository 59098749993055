/**
* @stylesheet components/RteWidgets.scss RteWidgets
* @parent styleguide
*
* @description
* Styling for RteWidgets.
*
* version
* 3.0.0
*
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1, :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2, :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Buttons
  *
  * @description
  * Contains style for a button
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1, :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2, :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 - Buttons
 *
 * Description: Style for buttons
 * -----------------------------------------------------------------------------
 */
:global .rteContent .promoExtended .content .container .overlay .promo-button a, :global .cke_editable .promoExtended .content .container .overlay .promo-button a, :global .rteContent .signup .form a, :global .cke_editable .signup .form a {
  border: 0;
  display: inline-block;
  padding: 1.6rem 2.1rem;
  background-color: #008080;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.4s ease-out;
  text-decoration: none;
  border-radius: 1rem;
  font-size: 1.6rem; }
  :global .rteContent .promoExtended .content .container .overlay .promo-button a:hover, :global .cke_editable .promoExtended .content .container .overlay .promo-button a:hover, :global .rteContent .signup .form a:hover, :global .cke_editable .signup .form a:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #006767; }
  :global .rteContent .promoExtended .content .container .overlay .promo-button a:active, :global .cke_editable .promoExtended .content .container .overlay .promo-button a:active, :global .rteContent .signup .form a:active, :global .cke_editable .signup .form a:active {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0 0 0.8rem rgba(82, 168, 236, 0.6); }

/**
  * Form
  *
  * @description
  * Contains common styling for form elements
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Input field
 * 2.0 - Search field
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1, :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2, :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1, :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1, :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .promoExtended .content .container .overlay p, :global .cke_editable .promoExtended .content .container .overlay p, :global .rteContent .signup .content, :global .cke_editable .signup .content, :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
 * 1.0 Input field
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 Search field
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
 * Table of Contents:
 *
 * 1.0 - Layout + BlockHeader
 * 2.0 - Latest Articles
 * 3.0 - Events
 * 4.0 - Promo Extended
 * 5.0 - Sign up
 * 6.0 - Image block
 * 7.0 - List block
 * 8.0 - Article Highlights
 * -----------------------------------------------------------------------------
 */
:global .rteContent, :global .cke_editable {
  /**
		 * 1.0 Layout + BlockHeader
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 2.0 Latest Articles
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 3.0 Events
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 4.0 Promo Extended
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 5.0 Sign Up
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 6.0 Image block
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 7.0 List block
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 8.0 Article Highlights
		 * -----------------------------------------------------------------------------
		 */ }
  :global .rteContent .layout, :global .cke_editable .layout {
    overflow: hidden;
    display: block; }
    :global .rteContent .layout.layoutWhite, :global .cke_editable .layout.layoutWhite {
      background-color: #fff; }
    :global .rteContent .layout.layoutTurquoise, :global .cke_editable .layout.layoutTurquoise {
      background-color: #E5E5E5; }
    :global .rteContent .layout .layoutContent, :global .cke_editable .layout .layoutContent {
      margin: 8rem auto;
      max-width: 1440px; }
      @media (max-width: 1024px) {
        :global .rteContent .layout .layoutContent, :global .cke_editable .layout .layoutContent {
          margin: 8rem 3.2rem; } }
      @media (max-width: 767px) {
        :global .rteContent .layout .layoutContent, :global .cke_editable .layout .layoutContent {
          margin: 3.2rem 2.4rem; } }
  :global .rteContent .blockHeader h1, :global .cke_editable .blockHeader h1 {
    margin-bottom: 0.8rem; }
  :global .rteContent .blockHeader h2, :global .cke_editable .blockHeader h2 {
    color: #008080;
    margin-bottom: 0; }
  @media all and (min-width: 0px) {
    :global .rteContent .latestArticles .articles-subtitle, :global .cke_editable .latestArticles .articles-subtitle {
      font-size: 3rem;
      margin-top: 0;
      line-height: 3.2rem;
      margin-bottom: 1.2rem; } }
  @media all and (min-width: 768px) {
    :global .rteContent .latestArticles .articles-subtitle, :global .cke_editable .latestArticles .articles-subtitle {
      font-size: 3.2rem;
      margin-top: 0;
      line-height: 4rem;
      margin-bottom: 1.6rem; } }
  :global .rteContent .latestArticles .articles, :global .cke_editable .latestArticles .articles {
    margin: 2.4rem 0;
    display: flex;
    flex-wrap: wrap; }
  :global .rteContent .latestArticles .blockArticle, :global .cke_editable .latestArticles .blockArticle {
    padding-right: 1.6rem;
    margin-bottom: 2.4rem;
    width: 25%; }
    :global .rteContent .latestArticles .blockArticle img, :global .cke_editable .latestArticles .blockArticle img {
      width: 100%; }
  :global .rteContent .events .eventsContainer, :global .cke_editable .events .eventsContainer {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap; }
    :global .rteContent .events .eventsContainer .event, :global .cke_editable .events .eventsContainer .event {
      width: 30%;
      background-color: #fff;
      border-radius: 1rem;
      padding: 1.6rem;
      margin: 0.8rem;
      text-align: center; }
      :global .rteContent .events .eventsContainer .event img, :global .cke_editable .events .eventsContainer .event img {
        width: 100%; }
  :global .rteContent .promoExtended, :global .cke_editable .promoExtended {
    position: relative;
    max-height: 80rem; }
    :global .rteContent .promoExtended .image img, :global .cke_editable .promoExtended .image img {
      width: 100% !important;
      max-height: 80rem;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
    @media print {
      :global .rteContent .promoExtended .image img, :global .cke_editable .promoExtended .image img {
        display: none; } }
    :global .rteContent .promoExtended .content, :global .cke_editable .promoExtended .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      pointer-events: none; }
      :global .rteContent .promoExtended .content .container, :global .cke_editable .promoExtended .content .container {
        width: 1440px;
        margin: 0 auto;
        position: relative; }
        :global .rteContent .promoExtended .content .container .overlay, :global .cke_editable .promoExtended .content .container .overlay {
          width: 55%;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          background-color: rgba(26, 23, 27, 0.65);
          color: #fff;
          padding: 3.2rem 2.4rem;
          pointer-events: all; }
          :global .rteContent .promoExtended .content .container .overlay h1, :global .cke_editable .promoExtended .content .container .overlay h1 {
            margin-bottom: 1.5rem; }
  :global .rteContent .signup, :global .cke_editable .signup {
    display: flex; }
    :global .rteContent .signup .column, :global .cke_editable .signup .column {
      width: 50%; }
    :global .rteContent .signup .form, :global .cke_editable .signup .form {
      display: flex;
      align-content: normal;
      align-items: flex-end; }
      :global .rteContent .signup .form a, :global .cke_editable .signup .form a {
        padding: 0.8rem 2.1rem; }
        @media all and (min-width: 0px) {
          :global .rteContent .signup .form a, :global .cke_editable .signup .form a {
            font-size: 1.5rem;
            margin-top: 0;
            line-height: 2.4rem;
            margin-bottom: 1rem; } }
        @media all and (min-width: 768px) {
          :global .rteContent .signup .form a, :global .cke_editable .signup .form a {
            font-size: 1.6rem;
            margin-top: 0;
            line-height: 2.4rem;
            margin-bottom: 1rem; } }
        @media (max-width: 767px) {
          :global .rteContent .signup .form a, :global .cke_editable .signup .form a {
            width: 100%;
            text-align: center; } }
    :global .rteContent .signup .image img, :global .cke_editable .signup .image img {
      max-width: 100%; }
    @media (max-width: 767px) {
      :global .rteContent .signup .image, :global .cke_editable .signup .image {
        display: none; } }
  :global .rteContent .imageBlock .image img, :global .cke_editable .imageBlock .image img {
    width: 100% !important;
    max-height: 80rem;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  @media print {
    :global .rteContent .imageBlock .image img, :global .cke_editable .imageBlock .image img {
      display: none; } }
  :global .rteContent .listBlock, :global .cke_editable .listBlock {
    margin-bottom: 2.4rem; }
    :global .rteContent .listBlock a, :global .cke_editable .listBlock a {
      text-decoration: none;
      color: #1A171B; }
      :global .rteContent .listBlock a:hover, :global .cke_editable .listBlock a:hover {
        text-decoration: underline; }
    :global .rteContent .listBlock .image, :global .cke_editable .listBlock .image {
      margin-bottom: 0.8rem; }
      :global .rteContent .listBlock .image img, :global .cke_editable .listBlock .image img {
        width: 100%;
        height: 18.6rem;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        transition: transform 0.4s ease-out; }
    :global .rteContent .listBlock .content h2, :global .cke_editable .listBlock .content h2 {
      color: #1A171B;
      margin-bottom: 0.8rem; }
    :global .rteContent .listBlock .content .description, :global .cke_editable .listBlock .content .description {
      color: #1A171B;
      margin-bottom: 0; }
  @media (max-width: 1024px) {
    :global .rteContent .listBlock, :global .cke_editable .listBlock {
      margin-bottom: 2.4rem; }
      :global .rteContent .listBlock .image img, :global .cke_editable .listBlock .image img {
        height: 20rem; } }
  @media (max-width: 767px) {
    :global .rteContent .listBlock .image img, :global .cke_editable .listBlock .image img {
      height: 28rem; } }
  :global .rteContent .articleHighlights, :global .cke_editable .articleHighlights {
    background-color: #fff; }
  :global .rteContent .headerHighlights, :global .cke_editable .headerHighlights {
    max-height: 72rem;
    position: relative;
    overflow: hidden; }
    :global .rteContent .headerHighlights img, :global .cke_editable .headerHighlights img {
      max-height: 72rem;
      width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
    :global .rteContent .headerHighlights .contentOverlay, :global .cke_editable .headerHighlights .contentOverlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column; }
      :global .rteContent .headerHighlights .contentOverlay.static, :global .cke_editable .headerHighlights .contentOverlay.static {
        position: static;
        margin-top: 4rem; }
    :global .rteContent .headerHighlights .titleOverlay, :global .cke_editable .headerHighlights .titleOverlay {
      background-color: rgba(26, 23, 27, 0.65);
      padding: 2.4rem 0.8rem; }
      :global .rteContent .headerHighlights .titleOverlay h1, :global .cke_editable .headerHighlights .titleOverlay h1 {
        color: #fff;
        margin-bottom: 0;
        padding: 0 2.4rem; }
  :global .rteContent .articlesContainer, :global .cke_editable .articlesContainer {
    padding: 2.4rem 0.8rem 2.4rem 2.4rem; }
    :global .rteContent .articlesContainer .blockArticle, :global .cke_editable .articlesContainer .blockArticle {
      display: inline-block;
      padding-right: 1.6rem;
      width: 25%; }
      :global .rteContent .articlesContainer .blockArticle img, :global .cke_editable .articlesContainer .blockArticle img {
        width: 100%; }

:global .rteContent .listBlock {
  width: 50%;
  display: inline-block;
  vertical-align: top; }
  :global .rteContent .listBlock :nth-of-type(odd) {
    padding-right: 0.8rem; }
  :global .rteContent .listBlock :nth-of-type(even) {
    padding-left: 0.8rem; }
  @media (max-width: 767px) {
    :global .rteContent .listBlock {
      width: 100%; } }
