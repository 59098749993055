/**
  * Pages
  *
  * @description
  * Contains page styles
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Global styles
 * 2.0 - Search page
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .html-page h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .html-page h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .html-page h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

:global .html-page a {
  color: #33518C;
  text-decoration: none; }
  :global .html-page a:hover {
    color: shade(#33518C, 10%);
    text-decoration: underline; }
  :global .html-page a:active {
    color: shade(#33518C, 20%);
    text-decoration: underline; }

:global .related-articles-link a {
  color: #008080;
  text-decoration: underline;
  font-weight: 500; }
  :global .related-articles-link a:hover {
    color: shade(#33518C, 10%);
    text-decoration: none; }
  :global .related-articles-link a:active {
    color: #1A171B;
    color: shade(#33518C, 20%);
    text-decoration: none; }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
 * 1.0 Global styles
 * -----------------------------------------------------------------------------
 */
:global .article-content {
  margin: 0 auto;
  width: 50%;
  min-width: 68.5rem;
  padding: 3.2rem 2.4rem 8rem; }
  @media (max-width: 767px) {
    :global .article-content {
      width: 100%;
      min-width: auto;
      padding: 3.2rem 2.4rem 8rem 2.4rem; } }
  :global .article-content .related-container:last-child {
    margin-bottom: 0; }

:global .organization-logo {
  float: left;
  padding-right: 0.8rem; }

:global .html-page {
  padding: 8rem; }
  @media (max-width: 767px) {
    :global .html-page {
      padding: 3.2rem; } }
  :global .html-page h1 {
    margin-bottom: 3.2rem; }
  :global .html-page a {
    color: #5D6266; }
    :global .html-page a:hover {
      color: #1A171B; }

:global .tagsContainer {
  list-style: none;
  margin: 4rem 0 4rem;
  padding: 0; }
  :global .tagsContainer li {
    display: inline-block; }
  :global .tagsContainer.tagsContainer-search {
    margin: 1.6rem 0 0 0; }

:global .related-container {
  margin: 4rem 0 4rem; }

:global .search-results {
  width: 73%;
  margin: 0 auto;
  padding: 4rem 0 8rem 0; }
  @media (max-width: 767px) {
    :global .search-results {
      width: 100%;
      padding: 4rem 2.4rem 0.8rem 2.4rem; } }

:global .search-filters {
  padding: 9rem 3.2rem 7.2rem 0; }
  @media (max-width: 767px) {
    :global .search-filters {
      padding: 0 2.4rem 2.4rem 2.4rem; } }

:global .related-articles {
  padding: 2.4rem;
  background-color: #fff;
  margin-top: 8rem; }
  :global .related-articles h2 {
    margin-top: 0; }
  @media (max-width: 768px) {
    :global .related-articles {
      margin-top: 3.2rem; } }

:global .related-articles-link {
  text-align: center;
  margin: 0.8rem 0; }
  @media all and (min-width: 0px) {
    :global .related-articles-link a {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  @media all and (min-width: 768px) {
    :global .related-articles-link a {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }

:global .at4-follow-outer, :global .at4-follow-outer * {
  display: none !important; }

:global .at-share-close-control, :global .at-share-close-control.at4-hide-content {
  visibility: visible !important;
  opacity: 1 !important; }
