/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .view-affiliate-list h2.list-header, :global .node-region-page .inner-header {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .view-affiliate-list h2.list-header {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .view-affiliate-list h2.list-header {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .node-region-page .inner-header {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .node-region-page .inner-header {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .affiliate_nav .rte p, :global .views-view-grid.cols-4 td .views-field a, :global .view-affiliate-list p, :global .aw_header .rte p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .affiliate_nav .rte p, :global .views-view-grid.cols-4 td .views-field a, :global .view-affiliate-list p, :global .aw_header .rte p {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

:global .content-main-holder {
  width: 80%;
  display: inline-block; }
  @media (max-width: 767px) {
    :global .content-main-holder {
      width: 100%;
      margin-top: 3.2rem; } }

:global .sidebar {
  width: 20%;
  display: inline-block;
  vertical-align: top; }
  @media (max-width: 767px) {
    :global .sidebar {
      display: none; } }

:global .affiliate_nav {
  background: #1A171B;
  min-height: 370px;
  position: relative;
  color: #fff;
  margin-left: -8rem;
  margin-right: -8rem;
  margin-top: -8rem;
  margin-bottom: 4.8rem;
  width: 1440px;
  padding: 4.8rem; }
  @media (max-width: 1440px) {
    :global .affiliate_nav {
      width: auto; } }
  @media (max-width: 767px) {
    :global .affiliate_nav {
      margin-left: -3.2rem;
      margin-right: -3.2rem;
      margin-top: -3.2rem;
      margin-bottom: 0; } }
  :global .affiliate_nav #affiliate_nav {
    padding: em(33px) em(27px); }
  :global .affiliate_nav h1 {
    color: #fff;
    margin-bottom: 15px; }
  :global .affiliate_nav .rte {
    width: 320px; }
    :global .affiliate_nav .rte p {
      line-height: 2.5rem; }
    @media (max-width: 767px) {
      :global .affiliate_nav .rte {
        width: 100%; } }
  :global .affiliate_nav select, :global .affiliate_nav .chosen-container {
    width: 275px;
    font-size: 1.6rem;
    height: 3rem; }
    :global .affiliate_nav select .chzn-drop li, :global .affiliate_nav .chosen-container .chzn-drop li {
      color: #1A171B; }
    @media (max-width: 767px) {
      :global .affiliate_nav select, :global .affiliate_nav .chosen-container {
        width: 100%; } }
  :global .affiliate_nav p.info {
    position: absolute;
    left: 4.8rem;
    bottom: 0;
    padding-right: 1.6rem;
    font-size: 1.4rem; }
    :global .affiliate_nav p.info a {
      color: #fff;
      text-decoration: underline !important; }
  :global .affiliate_nav + .views_panes {
    background-color: #fff;
    padding: em(45px) 0 0;
    margin: 0 auto;
    max-width: 1440px;
    font-size: em(16px); }
    :global .affiliate_nav + .views_panes a {
      color: #1A171B; }
  @media (max-width: 767px) {
    :global .affiliate_nav {
      background: #1A171B; }
      :global .affiliate_nav + .views_panes {
        display: none; } }

:global .view-affiliate-countries {
  padding: 0 50px; }

:global .views-view-grid {
  width: 100%; }
  :global .views-view-grid.cols-4 td {
    width: 25%;
    border: none;
    padding: 0; }
    :global .views-view-grid.cols-4 td .views-field {
      padding: 0 0 12px 7px; }
  :global .views-view-grid a {
    font-size: em(22px);
    line-height: em(26px, 22px); }

:global .view-affiliate-list h2.list-header {
  text-transform: uppercase;
  color: #DC001A;
  padding-bottom: 0.8rem;
  margin-bottom: 0;
  border-bottom: 0.2rem dotted #E5E5E5;
  width: 80%; }

:global #block-system-main .content ul.inline-nav {
  margin-bottom: 16px;
  padding-left: 0;
  margin-top: -16px;
  list-style: none;
  font-size: em(16px); }
  :global #block-system-main .content ul.inline-nav .arrow {
    color: #E5E5E5; }
  :global #block-system-main .content ul.inline-nav a {
    color: #DC001A; }

:global .node-affiliate {
  margin-bottom: em(0.8rem); }

:global .node-affiliate h3 {
  max-width: 500px;
  margin-bottom: 11px; }

:global .node-affiliate p {
  margin-bottom: em(10px); }

:global .node-affiliate p.label {
  font-weight: bold;
  margin-bottom: 0; }

:global .node-affiliate .col {
  width: 45%;
  padding-right: 5%;
  float: left; }

:global .node-region-page .image {
  margin-bottom: em(0.4rem); }

:global .node-region-page .image img {
  width: 100%; }

@media all and (min-width: 0px) {
  :global .node-region-page .rte {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

@media all and (min-width: 768px) {
  :global .node-region-page .rte {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

:global .node-region-page .rte ul, :global .node-region-page .rte ol {
  padding-left: em(20px); }

:global .node-region-page .inner-header {
  margin-bottom: 0; }

:global .aw_my-2 {
  padding: 0 0 12px 7px; }

:global .aw_my-2 a {
  font-size: 1.8rem;
  margin-top: 0;
  line-height: 3rem;
  margin-bottom: 2rem;
  color: #1A171B; }

:global #aw_widget_industriall h1 {
  color: #fff;
  font-size: 6rem !important;
  margin-top: 0;
  line-height: 7rem !important;
  margin-bottom: 15px; }

:global .aw_header p {
  font-size: 1.8rem;
  margin-top: 0;
  line-height: 3rem;
  margin-bottom: 2rem; }

:global #aw_widget_industriall .aw_container {
  max-width: 100% !important; }

:global #aw_app_result {
  padding: 0 50px; }

:global .aw_header {
  background: #1A171B;
  min-height: 370px;
  position: relative;
  color: #fff;
  margin-left: -8rem;
  margin-right: -8rem;
  margin-top: -8rem;
  margin-bottom: 4.8rem;
  width: 1440px;
  padding: 4.8rem;
  margin: 8rem auto; }
  @media (max-width: 1440px) {
    :global .aw_header {
      width: auto; } }
  @media (max-width: 767px) {
    :global .aw_header {
      margin-left: -3.2rem;
      margin-right: -3.2rem;
      margin-top: -3.2rem;
      margin-bottom: 0; } }
  :global .aw_header #affiliate_nav {
    padding: em(33px) em(27px); }
  :global .aw_header h1 {
    color: #fff;
    margin-bottom: 15px; }
  :global .aw_header .rte {
    width: 320px; }
    :global .aw_header .rte p {
      line-height: 2.5rem; }
    @media (max-width: 767px) {
      :global .aw_header .rte {
        width: 100%; } }
  :global .aw_header select, :global .aw_header .chosen-container {
    width: 275px;
    font-size: 1.6rem;
    height: 3rem; }
    :global .aw_header select .chzn-drop li, :global .aw_header .chosen-container .chzn-drop li {
      color: #1A171B; }
    @media (max-width: 767px) {
      :global .aw_header select, :global .aw_header .chosen-container {
        width: 100%; } }
  :global .aw_header p.info {
    position: absolute;
    left: 4.8rem;
    bottom: 0;
    padding-right: 1.6rem;
    font-size: 1.4rem; }
    :global .aw_header p.info a {
      color: #fff;
      text-decoration: underline !important; }
  :global .aw_header + .views_panes {
    background-color: #fff;
    padding: em(45px) 0 0;
    margin: 0 auto;
    max-width: 1440px;
    font-size: em(16px); }
    :global .aw_header + .views_panes a {
      color: #1A171B; }
  @media (max-width: 767px) {
    :global .aw_header {
      background: #1A171B; }
      :global .aw_header + .views_panes {
        display: none; } }
