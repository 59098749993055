/**
* @stylesheet components/RteTemplate.scss RteTemplate
* @parent styleguide
*
* @description
* Styling for RteTemplate.
*
* version
* 3.0.0
*
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle .imageContent .imageCaption p, :global .cke_editable .imageArticle .imageContent .imageCaption p {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle .imageContent .imageCaption p, :global .cke_editable .imageArticle .imageContent .imageCaption p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Buttons
  *
  * @description
  * Contains style for a button
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle.imageFull .imageContent .imageTitle h1, :global .cke_editable .imageArticle.imageFull .imageContent .imageTitle h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p, :global .rteContent .imageArticle.imageMedium .imageTitle h1, :global .cke_editable .imageArticle.imageMedium .imageTitle h1 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent .imageArticle .imageContent .imageCaption p, :global .cke_editable .imageArticle .imageContent .imageCaption p {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

@media all and (min-width: 768px) {
  :global .rteContent .imageArticle .imageContent .imageCaption p, :global .cke_editable .imageArticle .imageContent .imageCaption p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 - Buttons
 *
 * Description: Style for buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
 * Table of Contents:
 *
 * 1.0 - Styles
 * - 1.1 - Raised capital letter 
 * - 1.2 - Turquoise color
 * - 1.3 - Regular and large blockquote (also template)
 * - 1.4 - Ul-list with arrow icons
 * - 1.5 - Unstyled list
 * - 1.6 - Social media icons
 * 2.0 - Turquoise box
 * 3.0 - Images in article - Article-width/Full-width
 * - 3.1 - General styling
 * - 3.2 - Article-width
 * - 3.3 - Full-width
 * - 3.4 - Full color overlay
 * -----------------------------------------------------------------------------
 */
:global .rteContent, :global .cke_editable {
  /**
		 * 1.0 Styles
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.1 Raised capital letter
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.2 Turquoise color
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.3 Regular and large blockquote (also template)
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.4 Ul-list with icon arrows
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.5 Unstyled list
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 1.6 Social media icons 
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 2.0 Turquoise box
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 3.0 Images in article - Article-width/Full-width
		 * -----------------------------------------------------------------------------
		 */
  /**
		 * 3.1 General styling
		 * -----------------------------------------------------------------------------
		 */ }
  :global .rteContent .raised, :global .cke_editable .raised {
    color: #008080;
    font-weight: 500; }
    @media all and (min-width: 0px) {
      :global .rteContent .raised, :global .cke_editable .raised {
        font-size: 4rem;
        margin-top: 0;
        line-height: 4rem;
        margin-bottom: 0.3rem; } }
    @media all and (min-width: 768px) {
      :global .rteContent .raised, :global .cke_editable .raised {
        font-size: 4rem;
        margin-top: 0;
        line-height: 4rem;
        margin-bottom: 0.4rem; } }
  :global .rteContent .turquoise, :global .cke_editable .turquoise {
    color: #008080; }
  :global .rteContent .regularQuote, :global .rteContent .largeQuote, :global .cke_editable .regularQuote, :global .cke_editable .largeQuote {
    margin-bottom: 3.2rem;
    margin-left: 3.2rem; }
    :global .rteContent .regularQuote blockquote, :global .rteContent .largeQuote blockquote, :global .cke_editable .regularQuote blockquote, :global .cke_editable .largeQuote blockquote {
      margin: 3.2rem 3.2rem 0.8rem 0; }
      :global .rteContent .regularQuote blockquote p, :global .rteContent .largeQuote blockquote p, :global .cke_editable .regularQuote blockquote p, :global .cke_editable .largeQuote blockquote p {
        margin-bottom: 0.8rem !important; }
    @media (max-width: 768px) {
      :global .rteContent .regularQuote cite, :global .rteContent .largeQuote cite, :global .cke_editable .regularQuote cite, :global .cke_editable .largeQuote cite {
        margin-left: 0 !important; } }
  :global .rteContent .largeQuote, :global .cke_editable .largeQuote {
    margin: 6.4rem 3.2rem 6.4rem -24%; }
    @media (max-width: 767px) {
      :global .rteContent .largeQuote, :global .cke_editable .largeQuote {
        margin: 3.2rem 3.2rem; }
        :global .rteContent .largeQuote blockquote p, :global .cke_editable .largeQuote blockquote p {
          font-size: 2.5rem !important;
          line-height: 3.5rem !important; } }
    @media all and (min-width: 0px) {
      :global .rteContent .largeQuote blockquote p, :global .cke_editable .largeQuote blockquote p {
        font-size: 4rem;
        margin-top: 0;
        line-height: 4.5rem;
        margin-bottom: 2.8rem; } }
    @media all and (min-width: 768px) {
      :global .rteContent .largeQuote blockquote p, :global .cke_editable .largeQuote blockquote p {
        font-size: 5.6rem;
        margin-top: 0;
        line-height: 6.4rem;
        margin-bottom: 3.1rem; } }
    :global .rteContent .largeQuote cite, :global .cke_editable .largeQuote cite {
      margin-left: 8rem; }
  :global .rteContent ul.arrowList, :global .cke_editable ul.arrowList {
    list-style: none;
    padding: 0; }
    :global .rteContent ul.arrowList li:before, :global .cke_editable ul.arrowList li:before {
      content: "\f054";
      font-family: 'icomoon' !important;
      padding-right: 1.6rem;
      opacity: 0.2;
      vertical-align: middle; }
  :global .rteContent ul.unstyledList, :global .cke_editable ul.unstyledList {
    list-style: none;
    padding: 0; }
  :global .rteContent .link, :global .cke_editable .link {
    margin: 1rem 0; }
    :global .rteContent .link:before, :global .cke_editable .link:before {
      font-family: 'icomoon' !important;
      padding-right: 1.6rem;
      opacity: 0.2;
      font-size: 2.8rem;
      vertical-align: middle; }
    :global .rteContent .link--facebook:before, :global .cke_editable .link--facebook:before {
      content: "\f082"; }
    :global .rteContent .link--twitter:before, :global .cke_editable .link--twitter:before {
      content: "\f081"; }
    :global .rteContent .link--youtube:before, :global .cke_editable .link--youtube:before {
      content: "\f166"; }
    :global .rteContent .link--google:before, :global .cke_editable .link--google:before {
      content: "\f0d4"; }
    :global .rteContent .link--instagram:before, :global .cke_editable .link--instagram:before {
      content: "\e900"; }
    :global .rteContent .link--mail:before, :global .cke_editable .link--mail:before {
      content: "\f0e0";
      font-size: 2.4rem; }
  :global .rteContent .boxTurquoise, :global .cke_editable .boxTurquoise {
    background-color: rgba(0, 128, 128, 0.05);
    padding: 2.4rem;
    margin: 3.2rem;
    margin-left: -2.4rem;
    margin-right: -2.4rem; }
    :global .rteContent .boxTurquoise h1, :global .rteContent .boxTurquoise h2, :global .rteContent .boxTurquoise h3, :global .rteContent .boxTurquoise h4, :global .rteContent .boxTurquoise h5, :global .rteContent .boxTurquoise h6, :global .cke_editable .boxTurquoise h1, :global .cke_editable .boxTurquoise h2, :global .cke_editable .boxTurquoise h3, :global .cke_editable .boxTurquoise h4, :global .cke_editable .boxTurquoise h5, :global .cke_editable .boxTurquoise h6 {
      color: #008080; }
    :global .rteContent .boxTurquoise :last-child, :global .cke_editable .boxTurquoise :last-child {
      margin-bottom: 0;
      padding-bottom: 0; }
  :global .rteContent .boxTurquoiseImage .imageArticle, :global .cke_editable .boxTurquoiseImage .imageArticle {
    margin-bottom: -0.5rem !important; }
  :global .rteContent .boxTurquoiseImage .boxTurquoise, :global .cke_editable .boxTurquoiseImage .boxTurquoise {
    margin: 0 0 3.2rem 0; }
  :global .rteContent .imageArticle, :global .cke_editable .imageArticle {
    max-height: 72rem;
    position: relative;
    overflow: hidden;
    /**
			 * 3.2 Article-width
			 * -----------------------------------------------------------------------------
			 */
    /**
			 * 3.3 Full-width
			 * -----------------------------------------------------------------------------
			 */
    /**
			 * 3.4 Full color overlay
			 * -----------------------------------------------------------------------------
			 */ }
    :global .rteContent .imageArticle img, :global .cke_editable .imageArticle img {
      max-height: 72rem;
      width: 100% !important;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
    :global .rteContent .imageArticle .imageContent, :global .cke_editable .imageArticle .imageContent {
      position: absolute;
      bottom: 0.4rem;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column; }
      :global .rteContent .imageArticle .imageContent .imageTitle, :global .cke_editable .imageArticle .imageContent .imageTitle {
        background-color: rgba(26, 23, 27, 0.65);
        padding: 1.6rem;
        text-align: center;
        flex-grow: 1; }
        :global .rteContent .imageArticle .imageContent .imageTitle h1, :global .rteContent .imageArticle .imageContent .imageTitle p, :global .cke_editable .imageArticle .imageContent .imageTitle h1, :global .cke_editable .imageArticle .imageContent .imageTitle p {
          color: #fff;
          margin-bottom: 0;
          padding: 0 2.4rem;
          pointer-events: all; }
        :global .rteContent .imageArticle .imageContent .imageTitle a, :global .cke_editable .imageArticle .imageContent .imageTitle a {
          color: #fff; }
      :global .rteContent .imageArticle .imageContent .imageCaption, :global .cke_editable .imageArticle .imageContent .imageCaption {
        background-color: rgba(26, 23, 27, 0.8);
        min-height: 4rem;
        width: 100%;
        text-align: right;
        padding: 0.8rem 1.6rem; }
        :global .rteContent .imageArticle .imageContent .imageCaption p, :global .cke_editable .imageArticle .imageContent .imageCaption p {
          color: #fff;
          font-style: italic;
          margin: 0;
          pointer-events: all; }
    @media (max-width: 1024px) {
      :global .rteContent .imageArticle .imageTitle, :global .cke_editable .imageArticle .imageTitle {
        padding: 2.4rem !important; } }
    @media (max-width: 767px) {
      :global .rteContent .imageArticle .imageTitle, :global .cke_editable .imageArticle .imageTitle {
        position: static !important;
        margin-top: -1rem; }
        :global .rteContent .imageArticle .imageTitle h1, :global .cke_editable .imageArticle .imageTitle h1 {
          padding: 0 !important;
          font-size: 2.5rem !important; }
      :global .rteContent .imageArticle .imageCaption, :global .cke_editable .imageArticle .imageCaption {
        position: static !important; } }
    :global .rteContent .imageArticle.imageMedium, :global .cke_editable .imageArticle.imageMedium {
      margin: 2.4rem 0;
      max-height: none; }
      :global .rteContent .imageArticle.imageMedium img, :global .cke_editable .imageArticle.imageMedium img {
        max-height: none; }
    :global .rteContent .imageArticle.imageFull, :global .cke_editable .imageArticle.imageFull {
      margin: 4rem -58% 4rem -58%; }
      :global .rteContent .imageArticle.imageFull .imageContent, :global .cke_editable .imageArticle.imageFull .imageContent {
        bottom: 0; }
      @media (max-width: 767px) {
        :global .rteContent .imageArticle.imageFull, :global .cke_editable .imageArticle.imageFull {
          margin: 4rem -5%; } }
      @media (max-width: 1280px) {
        :global .rteContent .imageArticle.imageFull .imageContent, :global .cke_editable .imageArticle.imageFull .imageContent {
          bottom: 0.4rem; } }
    @media print {
      :global .rteContent .imageArticle.imageFull, :global .cke_editable .imageArticle.imageFull {
        margin: 0;
        max-width: 100%;
        page-break-inside: avoid; }
        :global .rteContent .imageArticle.imageFull img, :global .cke_editable .imageArticle.imageFull img {
          width: auto;
          max-height: 250px;
          object-fit: cover; }
        :global .rteContent .imageArticle.imageFull figcaption.imageContent, :global .cke_editable .imageArticle.imageFull figcaption.imageContent {
          position: relative;
          margin: 0;
          margin-top: -10rem; } }
    :global .rteContent .imageArticle.imageOverlay .imageContent, :global .cke_editable .imageArticle.imageOverlay .imageContent {
      top: 0;
      pointer-events: none;
      justify-content: flex-end; }
      :global .rteContent .imageArticle.imageOverlay .imageContent .imageTitle, :global .cke_editable .imageArticle.imageOverlay .imageContent .imageTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
    :global .rteContent .imageArticle.colorOverlay .imageContent, :global .cke_editable .imageArticle.colorOverlay .imageContent {
      bottom: 0.4rem; }
    :global .rteContent .imageArticle.colorOverlay .imageTitle, :global .cke_editable .imageArticle.colorOverlay .imageTitle {
      background-color: rgba(0, 128, 128, 0.65); }
    :global .rteContent .imageArticle.colorOverlay .imageCaption, :global .cke_editable .imageArticle.colorOverlay .imageCaption {
      background-color: rgba(0, 128, 128, 0.8); }
