/**
  * @stylesheet components/ThumnailList.scss ThumnailList
  * @parent styleguide
  *
  * @description
  * Styling for a ThumnailList.
  *
  * version
  * 3.0.0
  *
**/
/**
 * Table of Contents:
 *
 * 1.0 - ThumbnailList
 * 2.0 - Modal
 * 3.0 - Previous/Next buttons
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
 * 1.0 ThumbnailList
 * -----------------------------------------------------------------------------
 */
.thumbnailList {
  background-color: #F9F9F9; }
  .thumbnailList .list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0 auto;
    width: 50%;
    padding: 2.4rem 2.4rem 1.6rem 2.4rem; }
    .thumbnailList .list .thumbnail {
      display: inline-block;
      padding-right: 0.8rem;
      padding-bottom: 0.3rem;
      position: relative;
      transition: transform 0.4s ease-out; }
      .thumbnailList .list .thumbnail img {
        object-fit: cover;
        font-family: 'object-fit: cover;';
        height: 5.6rem;
        width: 5.6rem; }
      .thumbnailList .list .thumbnail .overlay {
        background-color: rgba(26, 23, 27, 0.65);
        position: absolute;
        top: 0;
        left: 0;
        right: 0.8rem;
        bottom: 0.8rem;
        padding: 1.9rem; }
      .thumbnailList .list .thumbnail:hover {
        transform: scale(1.05); }
    .thumbnailList .list .flickrLink a {
      font-size: 1.6rem;
      color: #33518C;
      text-decoration: none; }
      .thumbnailList .list .flickrLink a .flickrText {
        padding-left: 0.8rem; }
    @media (max-width: 767px) {
      .thumbnailList .list {
        margin: 0;
        width: 100%; } }

@media print {
  .thumbnailList .list {
    display: none; }
    .thumbnailList .list .flickrLink {
      display: none; } }

/**
 * 2.0 Modal
 * -----------------------------------------------------------------------------
 */
:global .overlay {
  background-color: rgba(26, 23, 27, 0.8) !important; }

:global .modal-wrapper-video .modal {
  display: block; }

:global .modal-wrapper {
  width: 80%;
  margin: 0 auto;
  max-width: 1440px !important;
  pointer-events: all; }
  @media (max-width: 767px) {
    :global .modal-wrapper {
      width: 100%; } }

:global .modal {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center; }
  :global .modal .autoembed {
    pointer-events: all; }
  :global .modal iframe {
    width: 100% !important; }
  :global .modal header img {
    width: auto !important; }
    @media (max-width: 767px) {
      :global .modal header img {
        width: 100% !important; } }

/**
 * 3.0 Previous/Next buttons
 * -----------------------------------------------------------------------------
 */
.previousBtn, .nextBtn {
  font-size: 7rem;
  text-decoration: none;
  position: absolute;
  top: 44%;
  opacity: 0.5;
  transition: opacity 0.4s ease-out;
  pointer-events: all; }
  .previousBtn:hover, .nextBtn:hover {
    opacity: 1; }
  @media (max-width: 767px) {
    .previousBtn, .nextBtn {
      font-size: 5rem;
      top: 40%; } }

.previousBtn {
  left: -7.2rem; }
  @media (max-width: 767px) {
    .previousBtn {
      left: 1.6rem; } }

.nextBtn {
  right: -7.2rem; }
  @media (max-width: 767px) {
    .nextBtn {
      right: 1.6rem; } }
