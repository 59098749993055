/**
  * @stylesheet components/Icon.scss Icon
  * @parent styleguide
  *
  * @description
  * Styling for icons.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.latestArticles .showMore a {
  color: #008080;
  text-decoration: underline;
  font-weight: 500; }
  .latestArticles .showMore a:hover {
    color: shade(#33518C, 10%);
    text-decoration: none; }
  .latestArticles .showMore a:active {
    color: #1A171B;
    color: shade(#33518C, 20%);
    text-decoration: none; }

@media all and (min-width: 0px) {
  .latestArticles .subtitle {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  .latestArticles .subtitle {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

.latestArticles .articles {
  margin-top: 2.4rem; }

.latestArticles .showMore {
  text-align: center;
  display: block;
  margin-top: 2.4rem; }
  @media all and (min-width: 0px) {
    .latestArticles .showMore a {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  @media all and (min-width: 768px) {
    .latestArticles .showMore a {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
