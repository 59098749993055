/**
  * @stylesheet components/BlockArticle.scss BlockArticle
  * @parent styleguide
  *
  * @description
  * Styling for an article block.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.blockArticle .content h2 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .blockArticle .content h2 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  .blockArticle .content h2 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  .blockArticle .content p {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .blockArticle .content p {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.blockArticle {
  margin-bottom: 2.4rem; }
  .blockArticle a {
    text-decoration: none; }
    .blockArticle a:hover h2 {
      text-decoration: underline; }
    .blockArticle a:hover .image img {
      transform: scale(1.03); }
  .blockArticle .image {
    margin-bottom: 0.8rem; }
    .blockArticle .image img {
      width: 100%;
      height: 18.6rem;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      transition: transform 0.4s ease-out; }
  .blockArticle .content h2 {
    color: #1A171B;
    margin-bottom: 0.8rem; }
  .blockArticle .content p {
    color: #1A171B;
    margin-bottom: 0; }
    .blockArticle .content p .date {
      color: #008080;
      margin-right: 0.8rem;
      font-weight: 600; }

@media (max-width: 1024px) {
  .blockArticle {
    margin-bottom: 2.4rem; }
    .blockArticle .image img {
      height: 20rem; } }

@media (max-width: 767px) {
  .blockArticle .image img {
    height: 28rem; } }
