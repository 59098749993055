/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
.favoritesList .title {
  padding-left: 0.8rem; }

.favoritesList .number {
  color: #87888A;
  padding-right: 0.8rem; }

.favoritesList ul {
  min-width: 25rem;
  max-height: 30rem;
  overflow: scroll; }
  .favoritesList ul :last-child {
    border-bottom: none; }

.favoritesList .item {
  position: relative;
  border-bottom: 0.1rem solid #E5E5E5;
  margin: 0 !important; }
  .favoritesList .item .favorites {
    display: inline-block;
    font-size: 1.4rem !important;
    line-height: 2rem;
    margin: 0.8rem 0;
    padding-right: 1.6rem;
    pointer-events: all;
    font-weight: 500; }
  .favoritesList .item span {
    font-size: 1.4rem;
    position: absolute;
    top: 0.8rem;
    right: 1.6rem;
    z-index: 99;
    cursor: pointer; }
