/**
  * @stylesheet components/Icon.scss Icon
  * @parent styleguide
  *
  * @description
  * Styling for icons.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
@font-face {
  font-family: 'icomoon';
  src: url("/static/fonts/icomoon.eot?xzycan");
  src: url("/static/fonts/icomoon.eot?xzycan#iefix") format("embedded-opentype"), url("/static/fonts/icomoon.ttf?xzycan") format("truetype"), url("/static/fonts/icomoon.woff?xzycan") format("woff"), url("/static/fonts/icomoon.svg?xzycan#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-instagram:before {
  content: "\e900"; }

.icon-bars:before {
  content: "\f0c9"; }

.icon-navicon:before {
  content: "\f0c9"; }

.icon-reorder:before {
  content: "\f0c9"; }

.icon-search:before {
  content: "\f002"; }

.icon-close:before {
  content: "\f00d"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-times:before {
  content: "\f00d"; }

.icon-tag:before {
  content: "\f02b"; }

.icon-play:before {
  content: "\f04b"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-twitter-square:before {
  content: "\f081"; }

.icon-facebook-square:before {
  content: "\f082"; }

.icon-thumbs-o-up:before {
  content: "\f087"; }

.icon-globe:before {
  content: "\f0ac"; }

.icon-google-plus-square:before {
  content: "\f0d4"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-file-text-o:before {
  content: "\f0f6"; }

.icon-building-o:before {
  content: "\f0f7"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

.icon-spinner:before {
  content: "\f110"; }

.icon-file-text:before {
  content: "\f15c"; }

.icon-youtube-square:before {
  content: "\f166"; }

.icon-industry:before {
  content: "\f275"; }

.icon-flickr3:before {
  content: "\eaa5"; }

.icon-corner-up-left:before {
  content: "\e901"; }

.icon-heart:before {
  content: "\e902"; }

.icon-message-circle:before {
  content: "\e903"; }

.icon-repeat:before {
  content: "\e904"; }

.icon-share:before {
  content: "\e905"; }

/**
 * Colors
 */
.turquoise {
  color: #008080; }

.red {
  color: #DC001A; }

.blue {
  color: #33518C; }

.white {
  color: #fff; }

.grey {
  color: #87888A; }

.grey-light {
  color: #a9aaac; }

.grey-dark {
  color: #5D6266; }
