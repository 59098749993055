/**
  * @stylesheet components/SearchField.scss SearchField
  * @parent styleguide
  *
  * @description
  * Styling for a search field.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.dropDown {
  display: inline-block;
  margin: 0 0.4rem;
  position: relative; }
  .dropDown:hover .list {
    visibility: visible; }
  .dropDown .list {
    overflow: auto;
    z-index: 99;
    display: block;
    visibility: hidden;
    text-align: left;
    position: absolute;
    top: 2.6rem;
    left: 50%;
    margin-left: -11rem;
    width: 20rem;
    list-style: none;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    padding: 0.4rem 0; }
    .dropDown .list li {
      margin: 0.4rem 0;
      padding: 0 1.6rem; }
      .dropDown .list li a:hover {
        text-decoration: underline; }
  .dropDown a {
    text-decoration: none;
    color: #1A171B; }
  .dropDown .item {
    margin: 0.4rem 0;
    padding: 0 1.6rem;
    list-style: none; }
    .dropDown .item a:hover {
      text-decoration: underline; }
