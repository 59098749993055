/**
  * @stylesheet components/Icon.scss Icon
  * @parent styleguide
  *
  * @description
  * Styling for icons.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.footer h2 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .footer h2 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  .footer h2 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

.footer {
  color: #fff; }
  .footer .outerWrapper {
    background-color: #1A171B; }
    .footer .outerWrapper.grey {
      background-color: #5D6266; }
  .footer .logo {
    position: absolute;
    height: 25rem;
    opacity: 0.05;
    left: 16rem;
    top: 4rem; }
  .footer .content {
    display: flex;
    position: relative; }
    .footer .content .noTitle {
      margin-top: 7rem; }
      @media (max-width: 767px) {
        .footer .content .noTitle {
          margin-top: 0.8rem; } }
  .footer .bottomBar {
    background-color: #5D6266;
    padding: 1.6rem 2.4rem;
    text-align: center;
    max-width: 1440px;
    margin: 0 auto; }
    .footer .bottomBar p {
      margin: 0 !important; }

/**
 * Media queries
 * --------------------------------------------------------------------
 */
.footer .content {
  padding: 0 1.6rem; }
