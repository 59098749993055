/**
  * @stylesheet components/Layout.scss Layout
  * @parent styleguide
  *
  * @description
  * Styling for the Layout.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.layout {
  overflow: hidden;
  display: block; }
  .layout.white {
    background-color: #fff; }
  .layout.black {
    background-color: #1A171B; }
  .layout.turquoise {
    background-color: #E5E5E5; }
  .layout .content {
    margin: 8rem auto;
    max-width: 1440px; }
    @media (max-width: 1440px) {
      .layout .content {
        margin: 8rem 2.4rem; } }
    @media (max-width: 768px) {
      .layout .content {
        margin: 2.4rem; } }
    @media (max-width: 767px) {
      .layout .content {
        margin: 2.4rem 1.6rem; } }
