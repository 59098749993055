/**
* @stylesheet components/RteContent.scss RteContent
* @parent styleguide
*
* @description
* Styling for RteContent.
*
* version
* 3.0.0
*
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
:global .rteContent h1, :global .cke_editable h1, :global .rteContent h2, :global .cke_editable h2, :global .rteContent h3, :global .cke_editable h3, :global .rteContent h4, :global .rteContent h5, :global .rteContent h6, :global .cke_editable h4, :global .cke_editable h5, :global .cke_editable h6 {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  :global .rteContent h1, :global .cke_editable h1 {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent h1, :global .cke_editable h1 {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

@media all and (min-width: 0px) {
  :global .rteContent h2, :global .cke_editable h2 {
    font-size: 3rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent h2, :global .cke_editable h2 {
    font-size: 3.2rem;
    margin-top: 0;
    line-height: 4rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent h3, :global .cke_editable h3 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent h3, :global .cke_editable h3 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent h4, :global .rteContent h5, :global .rteContent h6, :global .cke_editable h4, :global .cke_editable h5, :global .cke_editable h6 {
    font-size: 2.2rem;
    margin-top: 0;
    line-height: 2.6rem;
    margin-bottom: 1.2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent h4, :global .rteContent h5, :global .rteContent h6, :global .cke_editable h4, :global .cke_editable h5, :global .cke_editable h6 {
    font-size: 2.4rem;
    margin-top: 0;
    line-height: 3.2rem;
    margin-bottom: 1.6rem; } }

@media all and (min-width: 0px) {
  :global .rteContent p, :global .cke_editable p, :global .rteContent ul, :global .rteContent ol, :global .cke_editable ul, :global .cke_editable ol, :global .rteContent table tr td, :global .cke_editable table tr td {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  :global .rteContent p, :global .cke_editable p, :global .rteContent ul, :global .rteContent ol, :global .cke_editable ul, :global .cke_editable ol, :global .rteContent table tr td, :global .cke_editable table tr td {
    font-size: 1.8rem;
    margin-top: 0;
    line-height: 3rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 0px) {
  :global .rteContent cite, :global .cke_editable cite, :global .rteContent.small p, :global .cke_editable.small p, :global .rteContent.small ul li, :global .cke_editable.small ul li {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

@media all and (min-width: 768px) {
  :global .rteContent cite, :global .cke_editable cite, :global .rteContent.small p, :global .cke_editable.small p, :global .rteContent.small ul li, :global .cke_editable.small ul li {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
@font-face {
  font-family: 'icomoon';
  src: url("/static/fonts/icomoon.eot?xzycan");
  src: url("/static/fonts/icomoon.eot?xzycan#iefix") format("embedded-opentype"), url("/static/fonts/icomoon.ttf?xzycan") format("truetype"), url("/static/fonts/icomoon.woff?xzycan") format("woff"), url("/static/fonts/icomoon.svg?xzycan#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

:global .rteContent, :global .cke_editable {
  margin-bottom: 0.8rem; }
  :global .rteContent a, :global .cke_editable a {
    color: #33518C;
    text-decoration: none; }
    :global .rteContent a:hover, :global .cke_editable a:hover {
      text-decoration: underline; }
  :global .rteContent blockquote, :global .cke_editable blockquote {
    color: #008080;
    margin: 3.2rem;
    border-left: 4px solid #E5E5E5;
    padding-left: 2.4rem; }
    :global .rteContent blockquote p, :global .cke_editable blockquote p {
      font-style: italic;
      font-weight: 500; }
      @media all and (min-width: 0px) {
        :global .rteContent blockquote p, :global .cke_editable blockquote p {
          font-size: 2.4rem;
          margin-top: 0;
          line-height: 3.4rem;
          margin-bottom: 2.8rem; } }
      @media all and (min-width: 768px) {
        :global .rteContent blockquote p, :global .cke_editable blockquote p {
          font-size: 2.4rem;
          margin-top: 0;
          line-height: 3.4rem;
          margin-bottom: 3.1rem; } }
  :global .rteContent cite, :global .cke_editable cite {
    color: #87888A;
    margin-left: 7.2rem; }
  :global .rteContent ul li, :global .rteContent ol li, :global .cke_editable ul li, :global .cke_editable ol li {
    padding: 0.4rem 0;
    margin: 0;
    line-height: 2.5rem; }
  :global .rteContent table, :global .cke_editable table {
    border: none;
    border-spacing: 0;
    margin: 2.4rem 0;
    border-top: 1px solid #E5E5E5; }
    :global .rteContent table tr:nth-child(even), :global .cke_editable table tr:nth-child(even) {
      background-color: #F9F9F9; }
    :global .rteContent table tr td, :global .cke_editable table tr td {
      border: none;
      padding: 0.8rem;
      border-bottom: 1px solid #E5E5E5; }
      :global .rteContent table tr td p, :global .cke_editable table tr td p {
        margin-bottom: 0; }
    :global .rteContent table tr th, :global .cke_editable table tr th {
      border: none;
      padding: 0.8rem;
      text-align: left;
      border-bottom: 1px solid #E5E5E5; }
  :global .rteContent img, :global .cke_editable img {
    width: 100%;
    height: auto; }
  :global .rteContent.small, :global .cke_editable.small {
    margin-bottom: 0; }
    :global .rteContent.small ul li, :global .cke_editable.small ul li {
      margin: 0; }
  :global .rteContent.white p, :global .rteContent.white a, :global .rteContent.white h1, :global .rteContent.white h2, :global .rteContent.white h3, :global .rteContent.white h4, :global .rteContent.white h5, :global .rteContent.white h6, :global .cke_editable.white p, :global .cke_editable.white a, :global .cke_editable.white h1, :global .cke_editable.white h2, :global .cke_editable.white h3, :global .cke_editable.white h4, :global .cke_editable.white h5, :global .cke_editable.white h6 {
    color: #fff; }
  :global .rteContent.white a, :global .cke_editable.white a {
    text-decoration: underline; }
    :global .rteContent.white a:hover, :global .cke_editable.white a:hover {
      text-decoration: none; }
  :global .rteContent span, :global .cke_editable span {
    color: inherit !important; }
