/**
  * @stylesheet components/Header.scss Header
  * @parent styleguide
  *
  * @description
  * Styling for the header.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.pageHeader {
  max-height: 16.8rem;
  background-color: #fff; }
  .pageHeader .innerContent {
    position: relative;
    padding: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1440px;
    margin: 0 auto; }
  .pageHeader .logo {
    width: 25%; }
    .pageHeader .logo a {
      display: block; }
      .pageHeader .logo a img {
        max-height: 12rem;
        width: auto; }
  .pageHeader .menu {
    width: 50%; }
  .pageHeader .search {
    width: 25%;
    margin-left: 2.4rem;
    text-align: right; }
  .pageHeader .topMenu {
    position: absolute;
    top: 2.4rem;
    right: 0; }
  .pageHeader .mobileToolsMenu {
    display: none; }
    .pageHeader .mobileToolsMenu a:not(:last-child) span {
      margin-right: 1.6rem; }
  .pageHeader .mobileMenu, .pageHeader .mobileMenuVisible, .pageHeader .mobileSearch, .pageHeader .mobileSearchVisible, .pageHeader .mobileLanguageSelect, .pageHeader .mobileLanguageSelectVisible {
    display: none;
    position: absolute;
    top: 7.5rem;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99; }
  .pageHeader .mobileLanguageSelect .languageList, .pageHeader .mobileLanguageSelectVisible .languageList {
    list-style-type: none;
    padding-left: 1rem;
    margin-top: 0.5rem; }
    .pageHeader .mobileLanguageSelect .languageList .languageItem, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem {
      font-weight: 500;
      margin-right: 0.8rem;
      margin-left: 1.5rem;
      position: relative;
      margin-bottom: 0; }
      @media all and (min-width: 0px) {
        .pageHeader .mobileLanguageSelect .languageList .languageItem, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.3rem; } }
      @media all and (min-width: 768px) {
        .pageHeader .mobileLanguageSelect .languageList .languageItem, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem {
          font-size: 2rem;
          margin-top: 0;
          line-height: 3.3rem;
          margin-bottom: 0.4rem; } }
      .pageHeader .mobileLanguageSelect .languageList .languageItem:not(:first-child), .pageHeader .mobileLanguageSelectVisible .languageList .languageItem:not(:first-child) {
        border-top: 1px solid #E5E5E5;
        padding-top: 0.4rem; }
      .pageHeader .mobileLanguageSelect .languageList .languageItem .languageLink, .pageHeader .mobileLanguageSelect .languageList .languageItem .languageLinkActive, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem .languageLink, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem .languageLinkActive {
        text-decoration: none;
        color: #1A171B;
        font-size: 1.6rem; }
      .pageHeader .mobileLanguageSelect .languageList .languageItem .languageLinkActive, .pageHeader .mobileLanguageSelectVisible .languageList .languageItem .languageLinkActive {
        color: #DC001A; }
  .pageHeader .mobileSearch, .pageHeader .mobileSearchVisible {
    padding: 1.6rem; }
  .pageHeader .mobileMenuVisible, .pageHeader .mobileSearchVisible, .pageHeader .mobileLanguageSelectVisible {
    display: block; }
  .pageHeader .mobileLanguageCode {
    text-transform: uppercase; }
    .pageHeader .mobileLanguageCode span {
      margin-right: 0 !important;
      margin-left: 0.4rem; }
      .pageHeader .mobileLanguageCode span:before {
        font-size: 2rem; }
  .pageHeader .iconLink {
    padding: 0.8rem 0 0.8rem 0.8rem;
    text-decoration: none;
    text-align: right;
    font-size: 2.8rem;
    color: inherit; }
  .pageHeader .translate {
    padding-left: 1.6rem;
    font-size: 1.6rem;
    padding-top: 1.6rem !important; }
    .pageHeader .translate span:not(#google_translate_element) {
      font-weight: bold; }
    .pageHeader .translate p, .pageHeader .translate #google_translate_element {
      font-size: 1.3rem; }
    .pageHeader .translate:before {
      content: "";
      border-top: 0.1rem solid #E5E5E5;
      width: 95%;
      position: absolute;
      margin-top: -1rem; }

/**
 * Media queries
 * --------------------------------------------------------------------
 */
@media (max-width: 1024px) {
  .pageHeader .logo {
    width: auto;
    margin-top: 1.6rem; }
    .pageHeader .logo a img {
      max-height: 9rem; }
  .pageHeader .menu {
    width: auto; }
  .pageHeader .search {
    margin-left: 0;
    width: 20%; } }

@media (max-width: 768px) {
  .pageHeader .logo a img {
    max-height: 4rem; }
  .pageHeader .topMenu {
    display: none; }
  .pageHeader .search {
    display: none; }
  .pageHeader .menu {
    display: none; }
  .pageHeader .mobileToolsMenu {
    display: block; }
  .pageHeader .innerContent {
    padding: 1.6rem; } }

@media (min-width: 769px) {
  .pageHeader .mobileMenu, .pageHeader .mobileMenuVisible, .pageHeader .mobileSearch, .pageHeader .mobileSearchVisible, .pageHeader .mobileLanguageSelect, .pageHeader .mobileLanguageSelectVisible {
    display: none; } }
