/**
  * @stylesheet components/_autoembed.scss Autoembed
  * @parent styleguide
  *
  * @description
  * Styling for embeds.
  *
  * version
  * 3.0.0
  *
**/
:global .autoembed iframe {
  border: 0; }

:global .autoembed--youtube, :global .autoembed--vimeo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 2rem; }
  :global .autoembed--youtube iframe, :global .autoembed--vimeo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media print {
  :global .autoembed--youtube, :global .autoembed--vimeo {
    display: none; } }
