/**
  * @stylesheet components/SearchHeader.scss SearchHeader
  * @parent styleguide
  *
  * @description
  * Styling for the search page header.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.searchHeader .content .title {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .searchHeader .content .title {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .searchHeader .content .title {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Form
  *
  * @description
  * Contains common styling for form elements
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Input field
 * 2.0 - Search field
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.searchHeader .content .title {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-weight: 800;
  font-style: normal;
  line-height: 2.6rem;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; }

@media all and (min-width: 0px) {
  .searchHeader .content .title {
    font-size: 3.6rem;
    margin-top: 0;
    line-height: 3.6rem;
    margin-bottom: 2rem; } }

@media all and (min-width: 768px) {
  .searchHeader .content .title {
    font-size: 6rem;
    margin-top: 0;
    line-height: 7rem;
    margin-bottom: 2.4rem; } }

/**
 * 1.0 Input field
 * -----------------------------------------------------------------------------
 */
.searchHeader .content .searchField input {
  -webkit-appearance: none;
  border: none;
  width: 100%;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  color: #1A171B;
  line-height: 1.5rem; }
  @media all and (min-width: 0px) {
    .searchHeader .content .searchField input {
      font-size: 1.5rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  @media all and (min-width: 768px) {
    .searchHeader .content .searchField input {
      font-size: 1.6rem;
      margin-top: 0;
      line-height: 2.4rem;
      margin-bottom: 1rem; } }
  .searchHeader .content .searchField input::placeholder {
    color: #87888A; }

/**
 * 2.0 Search field
 * -----------------------------------------------------------------------------
 */
.searchHeader .content .searchField {
  position: relative;
  display: inline-block;
  width: 100%; }
  @media (max-width: 768px) {
    .searchHeader .content .searchField input {
      font-size: 1.6rem; } }
  .searchHeader .content .searchField .searchIcon {
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
    line-height: 2rem;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit; }
    @media all and (min-width: 0px) {
      .searchHeader .content .searchField .searchIcon {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .searchHeader .content .searchField .searchIcon {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }

.searchHeader {
  position: relative;
  background-color: #1A171B; }
  .searchHeader .headerBg {
    max-height: 25rem;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    opacity: 0.5;
    margin-bottom: -0.5rem; }
  .searchHeader .content {
    position: absolute;
    bottom: 4rem;
    padding-left: 14.2rem;
    width: 80rem; }
    .searchHeader .content .title {
      color: #fff; }
    @media (max-width: 1100px) {
      .searchHeader .content {
        width: 80%; } }
    @media (max-width: 767px) {
      .searchHeader .content {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        width: 100%; } }
