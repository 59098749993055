/**
  * @stylesheet components/Pager.scss Pager
  * @parent styleguide
  *
  * @description
  * Styling for a pager.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
@media all and (min-width: 0px) {
  .pagination {
    font-size: 1.5rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

@media all and (min-width: 768px) {
  .pagination {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 2.4rem;
    margin-bottom: 1rem; } }

.pagination ul {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0.8rem 0 0 0; }
  .pagination ul li {
    display: inline-block;
    padding-right: 0.8rem;
    text-transform: lowercase; }
    .pagination ul li.active {
      pointer-events: none; }
      .pagination ul li.active a {
        text-decoration: none !important;
        color: #1A171B !important;
        pointer-events: none; }
    .pagination ul li a {
      color: #008080;
      text-decoration: underline;
      cursor: pointer; }
      .pagination ul li a:hover {
        text-decoration: none; }
    .pagination ul li:before {
      content: '|';
      padding-right: 0.8rem; }
    .pagination ul li:first-of-type:before {
      content: '';
      padding-right: 0; }
