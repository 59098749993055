/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
.addFavorites {
  margin: 0; }
  .addFavorites a {
    color: #33518C;
    text-decoration: none; }
    @media all and (min-width: 0px) {
      .addFavorites a {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .addFavorites a {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .addFavorites a .title {
      padding-left: 0.8rem; }
    .addFavorites a:hover .title {
      text-decoration: underline; }
  @media (max-width: 768px) {
    .addFavorites {
      margin: 0.8rem 0 2.4rem; } }
