/**
  * @stylesheet components/TopMenu.scss TopMenu
  * @parent styleguide
  *
  * @description
  * Styling for the top menu.
  *
  * version
  * 3.0.0
  *
**/
/**
  * Spacing
  *
  * @description
  * Contains defined spacings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Spacings
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Spacings
 * -----------------------------------------------------------------------------
 */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
  * Typography
  *
  * @description
  * Contains typography settings
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Typography
 * 2.0 - Mixins
 * - 2.1 - Font-size
 * --2.2 - Make font-size
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
 */
/**
  * Media queries
  *
  * @description
  * Contains defined media queries
  *
  * @version
  * 3.0.0
**/
/**
  * Table of Contents:
  *
  * 1.0 - Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * 1.0 Responsiveness and sizes
  * -----------------------------------------------------------------------------
  */
/**
  * Colors
  *
  * @description
  * Contains theme colors
  *
  * @version
  * 3.0.0
**/
/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */
/**
 * 1.0 Typography
 * -----------------------------------------------------------------------------
 */
/**
 * 2.0 - Mixins
 * -----------------------------------------------------------------------------
*/
/**
 * 2.1 - Font size
 *
 * Description: Handeling font-sizes with responsivness.
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 2.2 - Make font size
 *
 * Description: Utility function for mixin font-size
 * @See: http://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
 * -----------------------------------------------------------------------------
 */
/**
 * 3.0 - Extensions
 * -----------------------------------------------------------------------------
*/
.topMenu .list {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0; }
  .topMenu .list a {
    text-decoration: none;
    color: #1A171B; }
    @media all and (min-width: 0px) {
      .topMenu .list a {
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    @media all and (min-width: 768px) {
      .topMenu .list a {
        font-size: 1.6rem;
        margin-top: 0;
        line-height: 2.4rem;
        margin-bottom: 1rem; } }
    .topMenu .list a.topLink:hover {
      text-decoration: underline; }
  .topMenu .list .item {
    display: inline-block;
    padding-right: 2.4rem; }
    .topMenu .list .item.dropDown > div {
      padding-bottom: 0.2rem; }
    .topMenu .list .item.dropDown ul {
      width: 23rem; }
    .topMenu .list .item.dropDown span.bold {
      font-weight: 500; }
    .topMenu .list .item.dropDown span:first-child, .topMenu .list .item.dropDown span:last-child {
      padding: 0 0.4rem; }
    .topMenu .list .item.dropDown span.number {
      color: #87888A; }

.topMenu .translate {
  font-size: 1.6rem;
  padding-top: 1.6rem !important; }
  .topMenu .translate span:not(#google_translate_element) {
    font-weight: bold; }
  .topMenu .translate p, .topMenu .translate #google_translate_element {
    font-size: 1.3rem;
    margin-bottom: 0; }
  .topMenu .translate:before {
    content: "";
    border-top: 0.1rem solid #E5E5E5;
    width: 80%;
    position: absolute;
    margin-top: -1rem; }
